const optionBirthPlace = [
	// AGUSAN DEL NORTE
	{ value: "BUENAVISTA", label: "BUENAVISTA" },
	{ value: "BUTUAN CITY", label: "BUTUAN CITY" },
	{ value: "CABADBARAN CITY", label: "CABADBARAN CITY" },
	{ value: "CARMEN", label: "CARMEN" },
	{ value: "JABONGA", label: "JABONGA" },
	{ value: "KITCHARAO", label: "KITCHARAO" },
	{ value: "LAS NIEVES", label: "LAS NIEVES" },
	{ value: "MAGALLANES", label: "MAGALLANES" },
	{ value: "NASIPIT", label: "NASIPIT" },
	{ value: "REMEDIOS T. ROMUALDEZ", label: "REMEDIOS T. ROMUALDEZ" },
	{ value: "SANTIAGO", label: "SANTIAGO" },
	{ value: "TUBAY", label: "TUBAY" },

	// AGUSAN DEL SUR
	{ value: "BAYUGAN CITY", label: "BAYUGAN CITY" },
	{ value: "BUNAWAN", label: "BUNAWAN" },
	{ value: "ESPERANZA", label: "ESPERANZA" },
	{ value: "LA PAZ", label: "LA PAZ" },
	{ value: "LORETO", label: "LORETO" },
	{ value: "PROSPERIDAD", label: "PROSPERIDAD" },
	{ value: "ROSARIO", label: "ROSARIO" },
	{ value: "SAN FRANCISCO", label: "SAN FRANCISCO" },
	{ value: "SAN LUIS", label: "SAN LUIS" },
	{ value: "SANTA JOSEFA", label: "SANTA JOSEFA" },
	{ value: "SIBAGAT", label: "SIBAGAT" },
	{ value: "TALACOGON", label: "TALACOGON" },
	{ value: "TRENTO", label: "TRENTO" },
	{ value: "VERUELA", label: "VERUELA" },

	// DINAGAT ISLANDS
	{ value: "BASILISA (RIZAL)", label: "BASILISA (RIZAL)" },
	{ value: "CAGDIANAO", label: "CAGDIANAO" },
	{ value: "DINAGAT", label: "DINAGAT" },
	{ value: "LIBJO (ALBOR)", label: "LIBJO (ALBOR)" },
	{ value: "LORETO", label: "LORETO" },
	{ value: "SAN JOSE", label: "SAN JOSE" },
	{ value: "TUBAJON", label: "TUBAJON" },

	// SURIGAO DEL NORTE
	{ value: "ALEGRIA", label: "ALEGRIA" },
	{ value: "BACUAG", label: "BACUAG" },
	{ value: "BURGOS", label: "BURGOS" },
	{ value: "CLAVER", label: "CLAVER" },
	{ value: "DAPA", label: "DAPA" },
	{ value: "DEL CARMEN", label: "DEL CARMEN" },
	{ value: "GENERAL LUNA", label: "GENERAL LUNA" },
	{ value: "GIGAQUIT", label: "GIGAQUIT" },
	{ value: "MAINIT", label: "MAINIT" },
	{ value: "MALIMONO", label: "MALIMONO" },
	{ value: "PILAR", label: "PILAR" },
	{ value: "PLACER", label: "PLACER" },
	{ value: "SAN BENITO", label: "SAN BENITO" },
	{ value: "SAN FRANCISCO (ANAO-AON)", label: "SAN FRANCISCO (ANAO-AON)" },
	{ value: "SAN ISIDRO", label: "SAN ISIDRO" },
	{ value: "SANTA MONICA (SAPAO)", label: "SANTA MONICA (SAPAO)" },
	{ value: "SISON", label: "SISON" },
	{ value: "SOCORRO", label: "SOCORRO" },
	{ value: "SURIGAO CITY", label: "SURIGAO CITY" },
	{ value: "TAGANA-AN", label: "TAGANA-AN" },
	{ value: "TUBOD", label: "TUBOD" },

	// SURIGAO DEL SUR
	{ value: "BAROBO", label: "BAROBO" },
	{ value: "BAYABAS", label: "BAYABAS" },
	{ value: "BISLIG CITY", label: "BISLIG CITY" },
	{ value: "CAGWAIT", label: "CAGWAIT" },
	{ value: "CANTILAN", label: "CANTILAN" },
	{ value: "CARMEN", label: "CARMEN" },
	{ value: "CARRASCAL", label: "CARRASCAL" },
	{ value: "CORTES", label: "CORTES" },
	{ value: "HINATUAN", label: "HINATUAN" },
	{ value: "LANUZA", label: "LANUZA" },
	{ value: "LIANGA", label: "LIANGA" },
	{ value: "LINGIG", label: "LINGIG" },
	{ value: "MADRID", label: "MADRID" },
	{ value: "MARIHATAG", label: "MARIHATAG" },
	{ value: "SAN AGUSTIN", label: "SAN AGUSTIN" },
	{ value: "SAN MIGUEL", label: "SAN MIGUEL" },
	{ value: "TAGBINA", label: "TAGBINA" },
	{ value: "TAGO", label: "TAGO" },
	{ value: "TANDAG CITY", label: "TANDAG CITY" },
];

optionBirthPlace.sort((a, b) => {
	if (a.label < b.label) {
		return -1;
	}
	if (a.label > b.label) {
		return 1;
	}
	return 0;
});

export default optionBirthPlace;
