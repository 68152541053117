import { useEffect, useState } from "react";
import {
	Col,
	Row,
	Form,
	Typography,
	notification,
	Button,
	Space,
	Divider,
	Input,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatInputMask from "../../../../providers/FloatInputMask";
import optionRelationship from "../../../../providers/optionRelationship";

import dayjs from "dayjs";
import notificationErrors from "../../../../providers/notificationErrors";
import validateRules from "../../../../providers/validateRules";
import optionOccupation from "../../../../providers/optionOccupation";

import ModalAuthPassword from "../components/ModalAuthPassword";
import { GET, POST } from "../../../../providers/useAxiosQuery";

export default function ApplicantFormEmergencyContact(props) {
	const { formDisabled, profile_id, action } = props;

	const [form] = Form.useForm();
	const [formData, setFormData] = useState(null);

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	// Add Occupation
	const [currentOccupation, setCurrentOccupation] = useState(optionOccupation);
	const [newOccupation, setNewOccupation] = useState("");

	const handleAddOccupation = () => {
		if (newOccupation) {
			setCurrentOccupation((prevOptions) => [
				...prevOptions,
				{ label: newOccupation, value: newOccupation },
			]);
			setNewOccupation("");
		}
	};

	const { refetch: refetchEmergencyContact } = GET(
		`api/student_exams/${profile_id}`,
		["student_exams", "check_user_permission"],
		(res) => {
			if (res.data) {
				let data = res.data;

				// GUARDIAN INFORMATION
				let guardian_lastname = null;
				let guardian_firstname = null;
				let guardian_middlename = null;
				let relation = null;
				let contact_number = null;
				let address = null;

				if (
					data.profile_parent_informations &&
					data.profile_parent_informations.length > 0
				) {
					let guardianInfo = data.profile_parent_informations.find(
						(item) => item.relation !== "Father" && item.relation !== "Mother"
					);

					if (guardianInfo) {
						guardian_lastname = guardianInfo.lastname;
						guardian_firstname = guardianInfo.firstname;
						guardian_middlename = guardianInfo.middlename;
						relation = guardianInfo.relation;
						contact_number = guardianInfo.contact_number;
						address = guardianInfo.address;
					}
				}

				// SPOUSE INFORMATION
				let fullname = null;
				let occupation = null;
				let spouse_contact_number = null;
				let spouse_address = null;

				if (data.profile_spouses && data.profile_spouses.length > 0) {
					let spouseInfo = data.profile_spouses[0];

					if (spouseInfo) {
						fullname = spouseInfo.fullname;
						occupation = spouseInfo.occupation;
						spouse_contact_number = spouseInfo.contact_number;
						spouse_address = spouseInfo.address;
					}
				}

				// INSURANCE PURPOSE
				let sponsor = null;
				let monthly_income = null;
				let beneficiary_fullname = null;
				let sponsor_birthdate = null;

				if (data.profile_insurances && data.profile_insurances.length > 0) {
					let insuranceInfo = data.profile_insurances[0];

					sponsor = insuranceInfo.sponsor;
					monthly_income = insuranceInfo.monthly_income;
					beneficiary_fullname = insuranceInfo.fullname;

					sponsor_birthdate = insuranceInfo.birthdate
						? dayjs(insuranceInfo.birthdate)
						: null;
				}

				form.setFieldsValue({
					guardian_lastname,
					guardian_firstname,
					guardian_middlename,
					relation,
					contact_number,
					address,

					fullname,
					occupation,
					spouse_contact_number,
					spouse_address,

					sponsor,
					monthly_income,
					beneficiary_fullname,
					sponsor_birthdate: sponsor_birthdate,
				});
			}
		},
		false
	);

	useEffect(() => {
		refetchEmergencyContact();
	}, [profile_id, refetchEmergencyContact]);

	const {
		mutate: mutateEmergencyContactUpdate,
		isLoading: isLoadingUpdateChanges,
	} = POST(`api/update_emergency_contact`, "student_exams");

	const onFinish = (auth_password) => {
		let values = formData;

		let sponsor_birthdate = values.sponsor_birthdate
			? dayjs(values.sponsor_birthdate, "MM-DD-YYYY").format("YYYY-MM-DD")
			: null;

		let data = {
			...values,
			sponsor_birthdate: sponsor_birthdate,
			profile_id: parseInt(profile_id, 10),

			// Auth Password Address for Changes
			auth_password: auth_password,
		};

		mutateEmergencyContactUpdate(data, {
			onSuccess: (res) => {
				notification.success({
					message: "Emergency Contact Updated",
					description: res.message,
				});
				setToggleModalAuthPassword({
					open: false,
					data: null,
				});
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Row gutter={[18, 0]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Typography.Title level={4} type="danger" className="mt-0">
						Person to Contact in Case of Emergency
					</Typography.Title>
				</Col>

				{/* GUARDIAN */}
				<>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Typography.Title level={5} type="secondary" className="mt-0">
							Guardian
						</Typography.Title>
					</Col>
					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="guardian_lastname"
							rules={[
								validateRules.quillValidator,
								validateRules.alphabet,
								validateRules.trim,
								{
									min: 2,
									message: "Please enter at least 2 characters",
								},
							]}
						>
							<FloatInput
								label="Maiden Name"
								placeholder="Maiden Name"
								disabled={formDisabled}
								required={true}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="guardian_firstname"
							rules={[
								validateRules.quillValidator,
								validateRules.alphabet_symbol,
								validateRules.trim,
								{
									min: 3,
									message: "Please enter at least 3 characters",
								},
							]}
						>
							<FloatInput
								label="First Name"
								placeholder="First Name"
								disabled={formDisabled}
								required={true}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="guardian_middlename"
							rules={[
								validateRules.alphabet,
								validateRules.trim,
								{
									min: 2,
									message: "Please enter at least 2 characters",
								},
							]}
						>
							<FloatInput
								label="Middle Name"
								placeholder="Middle Name"
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item name="relation" rules={[validateRules.quillValidator]}>
							<FloatSelect
								label="Relationship"
								placeholder="Relationship"
								disabled={formDisabled}
								required={true}
								options={optionRelationship}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="contact_number"
							rules={[validateRules.phone, validateRules.quillValidator]}
						>
							<FloatInputMask
								label="Contact Number"
								placeholder="Contact Number"
								maskLabel="contact_number"
								maskType="(+63) 999 999 9999"
								required={true}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={18} xl={18}>
						<Form.Item
							name="address"
							rules={[
								validateRules.quillValidator,
								validateRules.address,
								validateRules.trim,
							]}
						>
							<FloatInput
								label="Address"
								placeholder="Address"
								required={true}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>
				</>

				{/* SPOUSE */}
				<>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Typography.Title level={5} type="secondary" className="mt-0">
							Spouse
						</Typography.Title>
					</Col>

					<Col xs={24} sm={12} md={12} lg={12} xl={12}>
						<Form.Item
							name="fullname"
							rules={[
								validateRules.alphabet_symbol,
								validateRules.trim,
								{
									min: 10,
									message: "Please enter at least 10 characters",
								},
							]}
						>
							<FloatInput
								label="Full Name"
								placeholder="Full Name"
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="spouse_contact_number"
							rules={[validateRules.phone, validateRules.quillValidator]}
						>
							<FloatInputMask
								label="Contact Number"
								placeholder="Contact Number"
								maskLabel="contact_number"
								maskType="(+63) 999 999 9999"
								required={true}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={12} lg={6} xl={6}>
						<Form.Item name="occupation">
							<FloatSelect
								label="Occupation"
								placeholder="Occupation"
								allowClear={true}
								disabled={formDisabled}
								options={currentOccupation}
								dropdownRender={(menu) => (
									<>
										{menu}
										<Divider
											style={{
												margin: "8px 0",
											}}
										/>
										<Space
											style={{
												padding: "0 8px 4px",
											}}
										>
											<Input
												style={{
													border: "1px solid",
												}}
												value={newOccupation}
												onChange={(e) => setNewOccupation(e.target.value)}
											/>
											<Button
												className="btn-main-primary"
												type="text"
												icon={<FontAwesomeIcon icon={faPlus} />}
												name="btn-add-occupation"
												onClick={handleAddOccupation}
											>
												Add Occupation
											</Button>
										</Space>
									</>
								)}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={18} xl={18}>
						<Form.Item
							name="spouse_address"
							rules={[
								validateRules.quillValidator,
								validateRules.address,
								validateRules.trim,
							]}
						>
							<FloatInput
								label="Address"
								placeholder="Address"
								required={true}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>
				</>

				{/* INSURANCE PURPOSE */}
				<>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Typography.Title level={5} type="secondary" className="mt-0">
							For Insurance Purposes
						</Typography.Title>
					</Col>

					<Col xs={24} sm={24} md={24} lg={12} xl={12}>
						<p className="mt-0">
							Who support or sponsor studies(not any scholarship grant)
						</p>
						<Form.Item name="sponsor">
							<FloatSelect
								label="Support/Sponsor"
								placeholder="Support/Sponsor"
								options={optionRelationship}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<p className="mt-0">Monthly Income of Parent/ Sponsor</p>
						<Form.Item name="monthly_income">
							<FloatSelect
								label="Monthly Income"
								placeholder="Monthly Income"
								options={[
									{ label: "10,000 and below", value: "10,000 and below" },
									{ label: "10,000 - 20,000", value: "10,000 - 20,000" },
									{ label: "20,001 and above", value: "20,001 and above" },
								]}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<p className="mt-0">Name of Insurance Beneficiary/ Breadwinner</p>
					</Col>

					<Col xs={24} sm={24} md={24} lg={12} xl={12}>
						<Form.Item
							name="beneficiary_fullname"
							rules={[
								validateRules.alphabet_symbol,
								validateRules.trim,
								{
									min: 10,
									message: "Please enter at least 10 characters",
								},
							]}
						>
							<FloatInput
								label="Full Name"
								placeholder="Full Name"
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="sponsor_birthdate"
							rules={[
								{
									validator: (_, value) => {
										const today = dayjs();
										const birthdate = dayjs(value);
										const age = today.year() - birthdate.year();

										if (age < 18 && age < 110) {
											return Promise.reject(
												new Error("Age must be at least 18 years old")
											);
										}
										return Promise.resolve();
									},
								},
							]}
						>
							<FloatDatePicker
								className="birthdate"
								label="Date of Birth"
								placeholder="Date of Birth"
								required={true}
								allowClear
								format={{
									format: "MM-DD-YYYY",
									type: "mask",
								}}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Button
							className="btn-main-primary mb-20 w-xs-100"
							onClick={() => {
								setFormData(form.getFieldsValue());
								setToggleModalAuthPassword({
									open: true,
								});
							}}
						>
							Change
						</Button>
					</Col>
				</>
			</Row>
			{/* Modal */}
			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
				onFinish2={onFinish}
				profile_id={profile_id}
				isLoadingUpdateChanges={isLoadingUpdateChanges}
			/>
		</Form>
	);
}
