const optionBloodType = [
	{ value: "blood-type", label: "-blood type-", disabled: true },
	{ value: "AB", label: "AB" },
	{ value: "AB +", label: "AB +" },
	{ value: "AB -", label: "AB -" },
	{ value: "B", label: "B" },
	{ value: "B -", label: "B -" },
	{ value: "B +", label: "B +" },
	{ value: "O", label: "O" },
	{ value: "O -", label: "O -" },
	{ value: "O +", label: "O +" },
];
export default optionBloodType;
