import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Col, Form, Row, Button } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import FloatInputPassword from "../../../../providers/FloatInputPassword";

import ModalFormPassword from "../components/ModalFormPassword";
import validateRules from "../../../../providers/validateRules";
import ModalFormEmail from "../components/ModalFormEmail";

export default function ApplicantFormAccountInfo(props) {
	const { formDisabled } = props;

	const location = useLocation();

	const [toggleModalFormPassword, setToggleModalFormPassword] = useState({
		open: false,
		data: null,
	});

	const [toggleModalFormEmail, setToggleModalFormEmail] = useState({
		open: false,
		data: null,
	});

	return (
		<>
			<Row gutter={[18, 0]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.Item
						name="username"
						rules={[
							validateRules.quillValidator,
							validateRules.username_validate,
							validateRules.trim,
						]}
					>
						<FloatInput
							label="Username"
							placeholder="Username"
							required
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.Item name="email" rules={[validateRules.quillValidator]}>
						<FloatInput
							label="Email"
							placeholder="Email"
							required
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>

				{location.pathname.includes("add") && (
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item
							name="password"
							rules={[validateRules.quillValidator, validateRules.password]}
						>
							<FloatInputPassword
								label="Password"
								placeholder="Password"
								autoComplete="new-password"
								required
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>
				)}

				{location.pathname.includes("edit") && (
					<>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Button
								type="link"
								className="p-0"
								onClick={() =>
									setToggleModalFormPassword({
										open: true,
									})
								}
							>
								Change Password
							</Button>
						</Col>

						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Button type="link" className="btn-main-primary p-0">
								CLICK HERE
							</Button>
							<span>to enabled 2-Factor Authentication (2FA)</span>
						</Col>
					</>
				)}
			</Row>

			<ModalFormEmail
				toggleModalFormEmail={toggleModalFormEmail}
				setToggleModalFormEmail={setToggleModalFormEmail}
			/>

			<ModalFormPassword
				toggleModalFormPassword={toggleModalFormPassword}
				setToggleModalFormPassword={setToggleModalFormPassword}
			/>
		</>
	);
}
