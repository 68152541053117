import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tabs, Col, Button } from "antd";

import { GET } from "../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faCalendarDay,
	faCalendarLinesPen,
	faMemo,
	faMemoCircleCheck,
} from "@fortawesome/pro-regular-svg-icons";
import TableApproval from "./components/TableApproval";
import TableExamStatus from "./components/TableExamStatus";
import TableExamResult from "./components/TableExamResult";
import TableApplicant from "./components/TableApplicant";

export default function PageApplicantList() {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		category: ["Walk-In", "Online"],
		status:
			location.pathname.includes("walk-in") ||
			location.pathname.includes("online")
				? "Active"
				: "Deactivated",
		from: location.pathname.includes("walk-in")
			? "walk-in"
			: location.pathname.includes("online")
			? "online"
			: "archived",
		tabActive: location.pathname.includes("walk-in")
			? "Walk-In List View"
			: location.pathname.includes("online")
			? "Online List View"
			: "Archived List View",
		exam_schedule_id: params.id,
		exam_status: "",
		schedule_status: "Applied",
		exam_result: "Available",
	});

	useEffect(() => {
		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			sort_field: "created_at",
			sort_order: "desc",
			category: [
				"Walk-In",
				"Online",
				"College",
				"College of Law",
				"Graduate Studies",
			],
			status:
				location.pathname.includes("walk-in") ||
				location.pathname.includes("online")
					? "Active"
					: "Deactivated",
			from: location.pathname.includes("walk-in")
				? "walk-in"
				: location.pathname.includes("online")
				? "online"
				: "archived",
			tabActive: location.pathname.includes("walk-in")
				? "Walk-In List View"
				: location.pathname.includes("online")
				? "Online List View"
				: "Archived List View",
			exam_schedule_id: params.id,
			exam_status: "",
			schedule_status: "Applied",
			exam_result: "Available",
		});

		return () => {};
	}, [location]);

	const {
		data: dataStudentExam,
		refetch: refetchStudentExam,
	} = GET(
		`api/student_exams?${new URLSearchParams(tableFilter)}`,
		"student_exams_list",
		(res) => {}
	);

	const { data: dataExamCategory } = GET(
		`api/ref_exam_category`,
		"ref_exam_category_list"
	);

	useEffect(() => {
		refetchStudentExam();

		return () => {};
	}, [tableFilter]);

	let labelKeyObject;

	if (location.pathname.includes("walk-in")) {
		labelKeyObject = { label: "Walk-In List View", key: "Walk-In List View" };
	} else if (location.pathname.includes("online")) {
		labelKeyObject = { label: "Online List View", key: "Online List View" };
	} else if (location.pathname.includes("archived")) {
		labelKeyObject = { label: "Archived List View", key: "Archived List View" };
	}

	return (
		<>
			<Col xs={24} sm={24} md={24} lg={24}>
				<Button
					type="link"
					className="primary-color w-0 auto h-0 p-0"
					onClick={() => {
						if (location.pathname.includes("archived")) {
							navigate(`/applicants/archived`);
						} else if (location.pathname.includes("walk-in")) {
							navigate("/applicants/walk-in");
						} else {
							navigate("/applicants/online");
						}
					}}
					name="btn_back"
					icon={<FontAwesomeIcon icon={faArrowLeft} />}
				>
					Back to Exam Schedule List
				</Button>
			</Col>

			<Tabs
				className="TABS"
				indicator={{
					size: (origin) => origin - 25,
					align: "end",
				}}
				defaultActiveKey="1"
				onChange={(key) => {
					let exam_status = "";
					if (key === "Exam Status List View") {
						exam_status = "Not Taken";
					}
					setTableFilter((ps) => ({ ...ps, tabActive: key, exam_status }));
				}}
				items={[
					{
						icon: <FontAwesomeIcon icon={faCalendarDay} />,
						label: labelKeyObject.label,
						key: labelKeyObject.key,
						children: (
							<TableApplicant
								dataSource={dataStudentExam}
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
								dataExamCategory={dataExamCategory}
							/>
						),
					},

					// Filter for archived view
					...(location.pathname.includes("archived")
						? []
						: [
								{
									icon: <FontAwesomeIcon icon={faCalendarLinesPen} />,
									label: "Approval Schedule List View",
									key: "Approval Schedule List View",
									children: (
										<Col xs={24} sm={24} md={24}>
											<TableApproval
												location={location}
												tableFilter={tableFilter}
												setTableFilter={setTableFilter}
												params={params}
												dataSource={dataStudentExam}
												dataExamCategory={dataExamCategory}
											/>
										</Col>
									),
								},
								{
									icon: <FontAwesomeIcon icon={faMemo} />,
									label: "Exam Status List View",
									key: "Exam Status List View",
									children: (
										<Col xs={24} sm={24} md={24}>
											<TableExamStatus
												location={location}
												dataSource={dataStudentExam}
												tableFilter={tableFilter}
												setTableFilter={setTableFilter}
												params={params}
												dataExamCategory={dataExamCategory}
											/>
										</Col>
									),
								},
								{
									icon: <FontAwesomeIcon icon={faMemoCircleCheck} />,
									label: "Exam Result List View",
									key: "Exam Result List View",
									children: (
										<Col xs={24} sm={24} md={24}>
											<TableExamResult
												location={location}
												dataSource={dataStudentExam}
												tableFilter={tableFilter}
												setTableFilter={setTableFilter}
												params={params}
												dataExamCategory={dataExamCategory}
											/>
										</Col>
									),
								},
						  ]),
				]}
			/>
		</>
	);
}
