import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Button, Col, Table, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { GET } from "../../../providers/useAxiosQuery";
import {
	TableGlobalSearch,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../providers/CustomTableFilter";
import dayjs from "dayjs";

export default function PageApplicant(props) {
	const { refetchPermissions } = props;

	const navigate = useNavigate();
	const location = useLocation();

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		status: "Active",
		from: location.pathname,
	});

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	const tooltipTitle = location.pathname.includes("applicants/online")
		? "View Online List"
		: "View Walk-in list";

	useEffect(() => {
		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			sort_field: "created_at",
			sort_order: "desc",
			status:
				location.pathname === "/aplicants-schedule/walk-in"
					? "Active"
					: "Deactivated",
			from: location.pathname,
		});

		return () => {};
	}, [location]);

	const {
		data: dataExamScheduleSource,
		refetch: refetchExamScheduleSource,
	} = GET(
		`api/ref_exam_schedule?${new URLSearchParams(tableFilter)}`,
		"ref_exam_schedule_list",
		(res) => {
			refetchPermissions();
		}
	);

	// console.log("dataExamScheduleSource: ", dataExamScheduleSource);

	useEffect(() => {
		refetchExamScheduleSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	useTableScrollOnTop("tblExamSchedule", location.pathname);

	return (
		<Row gutter={[12, 12]}>
			{location.pathname === "/applicants/walk-in" ? (
				<Col xs={24} sm={24} md={24}>
					<Button
						className=" btn-main-primary btn-main-invert-outline b-r-none hide"
						icon={<FontAwesomeIcon icon={faPlus} />}
						onClick={() => navigate(`${location.pathname}/add`)}
						name="btn_add"
					>
						Add Walk-In Applicant
					</Button>
				</Col>
			) : null}

			<Col xs={24} sm={24} md={24}>
				<Row gutter={[12, 12]} id="tbl_wrapper_exam_schedule">
					<Col xs={24} sm={24} md={24}>
						<div className="tbl-top-filter">
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
							<TableGlobalSearch
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
					</Col>
					<Col xs={24} sm={24} md={24}>
						<Table
							id="tblExamSchedule"
							className="ant-table-default ant-table-striped"
							dataSource={
								dataExamScheduleSource && dataExamScheduleSource.data.data
							}
							rowKey={(record) => record.id}
							pagination={false}
							onChange={onChangeTable}
							bordered={false}
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							scroll={{ x: "max-content" }}
							sticky={{
								offsetHeader: 0,
							}}
						>
							<Table.Column
								title="
								View List"
								key="action"
								dataIndex="action"
								align="center"
								render={(text, record) => {
									return (
										<>
											<Tooltip title={tooltipTitle}>
												<Button
													type="link"
													className="primary-color"
													onClick={() => {
														if (location.pathname.includes("walk-in")) {
															navigate(`/applicants/walk-in/${record.id}`);
														} else if (location.pathname.includes("online")) {
															navigate(`/applicants/online/${record.id}`, {
																state: {
																	examDate: record && record.exam_date,
																	examTimeIn: record && record.time_in,
																	examTimeInMerd:
																		record && record.time_in_meridiem,
																	examTimeOut: record && record.time_out,
																	examTimeOutMerd:
																		record && record.time_out_meridiem,
																},
															});
														} else if (location.pathname.includes("archived")) {
															navigate(`/applicants/archived/${record.id}`, {
																state: {
																	examDate: record && record.exam_date,
																	examTimeIn: record && record.time_in,
																	examTimeInMerd:
																		record && record.time_in_meridiem,
																	examTimeOut: record && record.time_out,
																	examTimeOutMerd:
																		record && record.time_out_meridiem,
																},
															});
														}
													}}
													name="btn_view"
												>
													<FontAwesomeIcon icon={faEye} />
												</Button>
											</Tooltip>
										</>
									);
								}}
							/>

							<Table.Column
								title="Exam Schedule"
								key="exam_date"
								dataIndex="exam_date"
								sorter={true}
								render={(text, record) => {
									const date = dayjs(record.exam_date).format("MMMM DD, YYYY");

									const time_in = record.time_in;
									const time_in_meridiem = record.time_in_meridiem;
									const time_out = record.time_out;
									const time_out_meridiem = record.time_out_meridiem;

									return (
										<>{` ${date} ( ${time_in} ${time_in_meridiem} - ${time_out} ${time_out_meridiem} )`}</>
									);
								}}
							/>
						</Table>
					</Col>
					<Col xs={24} sm={24} md={24}>
						<div className="tbl-bottom-filter">
							<TableShowingEntriesV2 />
							<TablePagination
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
								setPaginationTotal={dataExamScheduleSource?.data.total}
								showLessItems={true}
								showSizeChanger={false}
								tblIdWrapper="tbl_wrapper_exam_schedule"
							/>
						</div>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
