import { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Button, Upload, notification } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/pro-regular-svg-icons";

import FloatSelect from "../../../../providers/FloatSelect";
import validateRules from "../../../../providers/validateRules";
import { GET } from "../../../../providers/useAxiosQuery";
import ModalAuthPassword from "./ModalAuthPassword";

export default function ModalUploadExamResult(props) {
	const {
		toggleModalUploadExamResult,
		setToggleModalUploadExamResult,
		tableFilter,
	} = props;

	// console.log("toggleModalUploadExamResult: ", toggleModalUploadExamResult);

	const [form] = Form.useForm();
	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const { data: dataExamCategory } = GET(
		`api/ref_exam_category`,
		"ref_exam_category_list"
	);

	useEffect(() => {
		if (toggleModalUploadExamResult.open) {
			form.setFieldsValue({
				...toggleModalUploadExamResult.data,
			});
		} else {
			form.resetFields();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalUploadExamResult]);

	return (
		<>
			<Modal
				wrapClassName="modal-form-module"
				width={700}
				title="FORM EXAM RESULT"
				open={toggleModalUploadExamResult.open}
				onCancel={() => setToggleModalUploadExamResult(false)}
				forceRender
				footer={[
					<Button
						className="btn-main-primary outlined"
						key={1}
						onClick={() => {
							setToggleModalUploadExamResult(false);
							form.resetFields();
						}}
					>
						CANCEL
					</Button>,

					<Button
						className="btn-main-primary"
						type="primary"
						key={2}
						onClick={() => {
							const values = form.getFieldsValue();

							// console.log("values: ", values);

							setToggleModalAuthPassword({
								open: true,
								data: values,
								tableFilter: tableFilter,
							});
							setToggleModalUploadExamResult({
								open: false,
							});
						}}
					>
						SUBMIT
					</Button>,
				]}
			>
				<Form form={form}>
					<Row gutter={[12, 0]}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Form.Item
								name="exam_category_id"
								rules={[validateRules.required]}
							>
								<FloatSelect
									label="Exam Category"
									placeholder="Exam Category"
									required={true}
									options={
										Array.isArray(dataExamCategory?.data)
											? dataExamCategory.data.map((item) => {
													return {
														label: item.category,
														value: item.id,
													};
											  })
											: []
									}
								/>
							</Form.Item>
						</Col>

						<Form.Item
							name="file_excel"
							valuePropName="fileList"
							getValueFromEvent={(e) => {
								if (Array.isArray(e)) {
									return e;
								}

								return e?.fileList;
							}}
							// rules={[validateRules.quillValidator()]}
						>
							<Upload.Dragger
								className="upload-w-100 upload-hide-remove-icon"
								accept="application/excel"
								// multiple
								beforeUpload={(file) => {
									let error = false;
									const isLt2M = file.size / 102400 / 102400 < 5;
									if (!isLt2M) {
										notification.error({
											message: "Exam Result",
											description: "Excel must smaller than 5MB!",
										});
										error = Upload.LIST_IGNORE;
									}
									return error;
								}}
							>
								<p className="ant-upload-drag-icon">
									<FontAwesomeIcon
										icon={faFileArrowUp}
										className="m-r-xs"
										style={{
											fontSize: "24px",
										}}
									/>
								</p>
								<p className="ant-upload-text">
									Click or drag file to this area to upload
								</p>
								<p className="ant-upload-hint">
									Support for a single or bulk upload
								</p>
							</Upload.Dragger>
						</Form.Item>
					</Row>
				</Form>
			</Modal>

			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
			/>
		</>
	);
}
