import React from "react";
import { Row, Col, Form, Radio, Checkbox, Typography } from "antd";
import FloatInput from "../../../../providers/FloatInput";
import validateRules from "../../../../providers/validateRules";

export default function ApplicantFormHealthInfo(props) {
	const { form } = props;

	const handleDisabilities = (checkedValues) => {
		// handleDebounce(checkedValues, "decision_factors");
	};

	const handleDifficulties = (checkedValues) => {
		// handleDebounce(checkedValues, "decision_factors");
	};

	return (
		<>
			{/* disability */}
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="p-0 ml-10">
					<p className="mt-0 mb-0">
						<em>
							The College needs the following Information to provide you with
							the necessary assistance and support.
						</em>
					</p>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="p-0 ml-10">
					<p>
						<Typography.Text type="danger">* </Typography.Text>
						<b>Disability:</b> Are you suffering from chronic illness,
						disability or impairment?
					</p>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="ml-10">
					<Form.Item
						name="have_disability"
						className="mb-0"
						rules={[validateRules.quillValidator]}
					>
						<Radio.Group>
							<Radio value="Yes">Yes</Radio>
							<Radio value="No">No</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>

				<Form.Item shouldUpdate noStyle>
					{() => (
						<>
							{form.getFieldValue("have_disability") === "Yes" && (
								<>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className="p-0 ml-10"
									>
										<p>
											What type of disability do you have? (Please check all
											that apply)
										</p>
									</Col>

									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<Form.Item
											name="disability_type"
											className="ml-10 mb-0 disability-checkbox"
										>
											<Checkbox.Group
												onChange={handleDisabilities}
												className="checkbox-wrapper"
											>
												<Row gutter={[12, 0]}>
													<Checkbox value="Visual Impairment">
														Visual Impairment
													</Checkbox>
													<Checkbox value="Hearing"> Hearing</Checkbox>
													<Checkbox value="Disability affecting mobility">
														Disabilitly affecting mobility
													</Checkbox>
													<Checkbox value="Other physical disability">
														Other physical disability
													</Checkbox>
													<Checkbox value="Other medical condition (e.g. epilepsy/diabetes/asthma)">
														Other medical condition (e.g.
														epilepsy/diabetes/asthma)
													</Checkbox>
												</Row>
												<Row gutter={[12, 0]}>
													<Checkbox value="Emotional/behavioural difficulties">
														Emotional/behavioural difficulties
													</Checkbox>
													<Checkbox value="Mental ill health">
														Mental ill health
													</Checkbox>
													<Checkbox value="Temporary disability after illness/accident">
														Temporary disability after illness/accident
													</Checkbox>
													<Checkbox value="Profound/complex disabilities">
														Profound/complex disabilities
													</Checkbox>
													<Checkbox value="Multiple disabilities">
														Multiple disabilities
													</Checkbox>
													<Checkbox
														value="Other disability"
														className="checkbox-wrapper-item"
													>
														Other, please specify
													</Checkbox>
												</Row>
											</Checkbox.Group>
										</Form.Item>

										<Form.Item shouldUpdate noStyle>
											{() => {
												let show = false;
												let disability_type = form.getFieldValue(
													"disability_type"
												);

												if (
													disability_type &&
													disability_type.includes("Other disability")
												) {
													return (
														<Form.Item
															name="other_disability"
															rules={[
																validateRules.quillValidator,
																validateRules.trim,
															]}
														>
															<FloatInput
																id="otherFactor"
																label="Specify other disability"
																placeholder="Specify other disability"
															/>
														</Form.Item>
													);
												}
											}}
										</Form.Item>
									</Col>
								</>
							)}
						</>
					)}
				</Form.Item>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="p-0 ml-10 mt-0">
					<p>
						<Typography.Text type="danger">* </Typography.Text>
						<b>Learning Difficulty:</b> Do you consider that you have a learning
						difficulty?
					</p>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="ml-10">
					<Form.Item
						name="have_difficulty"
						className="mb-0"
						rules={[validateRules.quillValidator]}
					>
						<Radio.Group>
							<Radio value="Yes">Yes</Radio>
							<Radio value="No">No</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>

				<Form.Item shouldUpdate noStyle>
					{() => (
						<>
							{form.getFieldValue("have_difficulty") === "Yes" && (
								<>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className="p-0 ml-10"
									>
										<p>
											What type of learning difficulty do you have? (Please
											check all that apply)
										</p>
									</Col>

									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<Form.Item name="difficulty_type">
											<Checkbox.Group
												onChange={handleDifficulties}
												className="checkbox-wrapper learning-disability-checkbox"
											>
												<Checkbox value="Moderate learning difficulty">
													Moderate learning difficulty
												</Checkbox>
												<Checkbox value="Severe learning difficulty">
													Severe learning difficulty
												</Checkbox>
												<Checkbox value="Dyslexia (problems with words)">
													Dyslexia (problems with words)
												</Checkbox>
												<Checkbox value="Dyscalculia (problems with numbers/figures)">
													Dyscalculia (problems with numbers/figures)
												</Checkbox>
												<Checkbox value="Multiple learning difficulties">
													Multiple learning difficulties
												</Checkbox>
												<Checkbox
													value="Other Learning Difficulty"
													className="checkbox-wrapper-item"
												>
													Other, please specify
												</Checkbox>
											</Checkbox.Group>
										</Form.Item>

										<Form.Item shouldUpdate noStyle>
											{() => {
												let show = false;
												let difficulty_type = form.getFieldValue(
													"difficulty_type"
												);

												if (
													difficulty_type &&
													difficulty_type.includes("Other Learning Difficulty")
												) {
													return (
														<Form.Item
															name="other_difficulty"
															rules={[
																validateRules.quillValidator,
																validateRules.trim,
															]}
														>
															<FloatInput
																id="otherFactor"
																label="Specify other learning difficulty"
																placeholder="Specify other learning difficulty"
															/>
														</Form.Item>
													);
												}
											}}
										</Form.Item>
									</Col>
								</>
							)}
						</>
					)}
				</Form.Item>
			</Row>
		</>
	);
}
