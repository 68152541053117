const optionLanguage = [
	{ label: "Aklanon", value: "Aklanon" },
	{ label: "Bikol", value: "Bikol" },
	{ label: "Cebuano", value: "Cebuano" },
	{ label: "Chavacano", value: "Chavacano" },
	{ label: "Hiligaynon", value: "Hiligaynon" },
	{ label: "Ibanag", value: "Ibanag" },
	{ label: "Ilocano", value: "Ilocano" },
	{ label: "Ivatan", value: "Ivatan" },
	{ label: "Kapampangan", value: "Kapampangan" },
	{ label: "Kinaray-a", value: "Kinaray-a" },
	{ label: "Maguinadanao", value: "Maguinadanao" },
	{ label: "Maranao", value: "Maranao" },
	{ label: "Pangasinan", value: "Pangasinan" },
	{ label: "Sambal", value: "Sambal" },
	{ label: "Surigaonon", value: "Surigaonon" },
	{ label: "Tagalog", value: "Tagalog" },
	{ label: "Tausug", value: "Tausug" },
	{ label: "Waray", value: "Waray" },
	{ label: "Yakan", value: "Yakan" },
	{ label: "Others", value: "Others" },
];

optionLanguage.sort((a, b) => {
	if (a.label < b.label) {
		return -1;
	}
	if (a.label > b.label) {
		return 1;
	}
	return 0;
});

export default optionLanguage;
