import { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Button, Table, notification } from "antd";

import dayjs from "dayjs";
import FloatInput from "../../../../providers/FloatInput";

import validateRules from "../../../../providers/validateRules";
import ModalAuthPassword from "./ModalAuthPassword";

export default function ModalFormExamSheetNumber(props) {
	const {
		toggleModalFormExamSheetNumber,
		setToggleModalFormExamSheetNumber,
		dataSource,
		tableFilter,
	} = props;

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const [form] = Form.useForm();
	const [copyDataSource, setCopyDataSource] = useState([]);

	// console.log("copyDataSource: ", copyDataSource);

	// Update the OR Number input field
	const handleInputChange = (e, record) => {
		let copyDataSource1 = [...copyDataSource];
		let index = copyDataSource1.findIndex((data) => data.id === record.id);

		if (index !== -1) {
			copyDataSource1[index] = {
				...copyDataSource1[index],
				exam_sheet_number: e.target.value,
			};
		}

		setCopyDataSource(copyDataSource1);
	};

	useEffect(() => {
		if (toggleModalFormExamSheetNumber.open) {
			setCopyDataSource(
				dataSource.data.data.filter(
					(data) =>
						toggleModalFormExamSheetNumber &&
						toggleModalFormExamSheetNumber.ids &&
						toggleModalFormExamSheetNumber.ids.includes(data.id)
				)
			);
			form.setFieldsValue({
				...toggleModalFormExamSheetNumber.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormExamSheetNumber]);

	return (
		<>
			<Modal
				width={1200}
				wrapClassName="modal-form-module"
				title="FORM EXAM SHEET NUMBER"
				open={toggleModalFormExamSheetNumber.open}
				onCancel={() => {
					setToggleModalFormExamSheetNumber(false);
					form.resetFields();
					setCopyDataSource([]);
				}}
				forceRender
				footer={[
					<Button
						className="btn-main-primary outlined"
						key={1}
						onClick={() => {
							setToggleModalFormExamSheetNumber(false);
							form.resetFields();
							setCopyDataSource([]);
						}}
					>
						CANCEL
					</Button>,
					<Button
						className="btn-main-primary"
						type="primary"
						key={2}
						onClick={() => {
							const allOrNumbersFilled = copyDataSource.every(
								(item) =>
									item.exam_sheet_number && item.exam_sheet_number.trim() !== ""
							);

							if (!allOrNumbersFilled) {
								notification.error({
									message: "Applicants",
									description: "Please fill up all Exam Sheet Number",
								});
							} else {
								const newValues =
									copyDataSource &&
									copyDataSource.map((data) => {
										return {
											id: data.id,
											exam_schedule_id: data.exam_schedule_id,
											exam_sheet_number: data.exam_sheet_number,
											exam_status: data.exam_status,
											fullname: data.fullname,
										};
									});

								setToggleModalAuthPassword({
									open: true,
									data: newValues,
									tableFilter: tableFilter,
								});
								setToggleModalFormExamSheetNumber(false);
							}
						}}
						name="btn_submit"
					>
						SUBMIT
					</Button>,
				]}
			>
				<Form form={form}>
					<Row gutter={[12, 0]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Table
								id="tblApproval"
								className="ant-table-default ant-table-striped"
								dataSource={copyDataSource}
								rowKey={(record) => record.id}
								pagination={false}
								bordered={false}
								scroll={{ x: "max-content" }}
								sticky
							>
								<Table.Column
									title="Answer Sheet Number"
									key="exam_sheet_number"
									dataIndex="exam_sheet_number"
									width={200}
									render={(text, record) => (
										<FloatInput
											rules={[validateRules.quillValidator]}
											required={true}
											value={record.exam_sheet_number}
											onChange={(e) =>
												handleInputChange(e, record, "exam_sheet_number")
											}
											autoComplete="off"
											label="Input Number"
											placeholder="Input Number"
										/>
									)}
								/>

								<Table.Column
									title="Or Number"
									key="or_number"
									dataIndex="or_number"
									sorter={true}
									width={200}
								/>

								<Table.Column
									title="Student Name"
									key="fullname"
									dataIndex="fullname"
									sorter={true}
									width={200}
									render={(text, record) => {
										const fullname = record.fullname;
										return <>{` ${fullname} `}</>;
									}}
								/>

								<Table.Column
									title="Email"
									key="personal_email"
									dataIndex="personal_email"
									sorter={true}
									width={250}
								/>

								<Table.Column
									title="Exam Schedule"
									key="exam_schedule_id"
									dataIndex="exam_schedule_id"
									sorter={true}
									width={300}
									render={(text, record) => {
										const date = dayjs(record.exam_date).format(
											"MMMM DD, YYYY"
										);
										const exam_time = record.exam_time;

										return <>{` ${date} (${exam_time})`}</>;
									}}
								/>
							</Table>
						</Col>
					</Row>
				</Form>
			</Modal>
			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
			/>
		</>
	);
}
