import React, { useState } from "react";
import { Tabs } from "antd/lib";

import { GET } from "../../../../providers/useAxiosQuery";
import PageExamCategory from "../PageExamCategory/PageExamCategory";
import PageUser from "../PageUser/PageUser";
import PageDepartment from "../PageDepartment/PageDepartment";
import PageCourse from "../PageCourse/PageCourse";
import ModalAuthPassword from "./ModalAuthPassword";

export default function PageSettings() {
	const onChange = (key) => {
		setTableFilter((prevState) => ({
			...prevState,
			tabActive: getTabActiveValue(key),
			// Do not reset department_id here
		}));
	};

	const getTabActiveValue = (key) => {
		switch (key) {
			case "User":
				return "User";
			case "Exam Category":
				return "Exam Category";
			case "Department":
				return "Department";
			case "Course":
				return "Course";
			default:
				return "";
		}
	};

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		status: "Active",
		tabActive: getTabActiveValue("User"),
		isTrash: 0,
		department_id: "",
	});

	const { data: dataUser, refetch: refetchSourceUser } = GET(
		`api/users?${new URLSearchParams(tableFilter)}`,
		"users_list"
	);

	const { data: dataExamCategory, refetch: refetchSourceExamCategory } = GET(
		`api/ref_exam_category?${new URLSearchParams(tableFilter)}`,
		"ref_exam_category_list"
	);

	const { data: dataSourceDepartment, refetch: refetchSourceDepartment } = GET(
		`api/ref_department?${new URLSearchParams(tableFilter)}`,
		"ref_department_list"
	);

	const { data: dataSourceCourse, refetch: refetchSourceCourse } = GET(
		`api/ref_course?${new URLSearchParams(tableFilter)}`,
		"ref_course_list"
	);

	const tabListTitle = [
		// {
		// 	key: "User",
		// 	label: "User",
		// 	children: (
		// 		<PageUser
		// 			tableFilter={tableFilter}
		// 			setTableFilter={setTableFilter}
		// 			selectedRowKeys={selectedRowKeys}
		// 			setSelectedRowKeys={setSelectedRowKeys}
		// 			ModalAuthPassword={ModalAuthPassword}
		// 			dataUser={dataUser}
		// 			refetchSourceUser={refetchSourceUser}
		// 		/>
		// 	),
		// 	id: "User",
		// },
		{
			key: "Exam Category",
			label: "Exam Category",
			children: (
				<PageExamCategory
					tableFilter={tableFilter}
					setTableFilter={setTableFilter}
					selectedRowKeys={selectedRowKeys}
					setSelectedRowKeys={setSelectedRowKeys}
					dataExamCategory={dataExamCategory}
					refetchSourceExamCategory={refetchSourceExamCategory}
				/>
			),
			id: "Exam Category",
		},
		// {
		// 	key: "Department",
		// 	label: "Department",
		// 	children: (
		// 		<PageDepartment
		// 			tableFilter={tableFilter}
		// 			setTableFilter={setTableFilter}
		// 			selectedRowKeys={selectedRowKeys}
		// 			setSelectedRowKeys={setSelectedRowKeys}
		// 			dataSourceDepartment={dataSourceDepartment}
		// 			refetchSourceDepartment={refetchSourceDepartment}
		// 		/>
		// 	),
		// 	id: "Department",
		// },
		// {
		// 	key: "Course",
		// 	label: "Course",
		// 	children: (
		// 		<PageCourse
		// 			tableFilter={tableFilter}
		// 			setTableFilter={setTableFilter}
		// 			selectedRowKeys={selectedRowKeys}
		// 			setSelectedRowKeys={setSelectedRowKeys}
		// 			dataSourceCourse={dataSourceCourse}
		// 			refetchSourceCourse={refetchSourceCourse}
		// 		/>
		// 	),
		// 	id: "Course",
		// },
	];

	return (
		<>
			<Tabs
				defaultActiveKey="1"
				onChange={onChange}
				type="card"
				items={tabListTitle}
			/>

			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
			/>
		</>
	);
}
