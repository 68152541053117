const optionOccupation = [
	{ label: "accountant", value: "accountant" },
	{ label: "actor", value: "actor" },
	{ label: "actuary", value: "actuary" },
	{
		label: "adhesive bonding machine tender",
		value: "adhesive bonding machine tender",
	},
	{ label: "adjudicator", value: "adjudicator" },
	{ label: "administrative assistant", value: "administrative assistant" },
	{
		label: "administrative services manager",
		value: "administrative services manager",
	},
	{ label: "adult education teacher", value: "adult education teacher" },
	{ label: "advertising manager", value: "advertising manager" },
	{ label: "advertising sales agent", value: "advertising sales agent" },
	{ label: "aerobics instructor", value: "aerobics instructor" },
	{ label: "aerospace engineer", value: "aerospace engineer" },
	{
		label: "aerospace engineering technician",
		value: "aerospace engineering technician",
	},
	{ label: "agent", value: "agent" },
	{ label: "agricultural engineer", value: "agricultural engineer" },
	{
		label: "agricultural equipment operator",
		value: "agricultural equipment operator",
	},
	{ label: "agricultural grader", value: "agricultural grader" },
	{ label: "agricultural inspector", value: "agricultural inspector" },
	{ label: "agricultural manager", value: "agricultural manager" },
	{
		label: "agricultural sciences teacher",
		value: "agricultural sciences teacher",
	},
	{ label: "agricultural sorter", value: "agricultural sorter" },
	{ label: "agricultural technician", value: "agricultural technician" },
	{ label: "agricultural worker", value: "agricultural worker" },
	{ label: "air conditioning installer", value: "air conditioning installer" },
	{ label: "air conditioning mechanic", value: "air conditioning mechanic" },
	{ label: "air traffic controller", value: "air traffic controller" },
	{
		label: "aircraft cargo handling supervisor",
		value: "aircraft cargo handling supervisor",
	},
	{ label: "aircraft mechanic", value: "aircraft mechanic" },
	{
		label: "aircraft service technician",
		value: "aircraft service technician",
	},
	{ label: "airline copilot", value: "airline copilot" },
	{ label: "airline pilot", value: "airline pilot" },
	{ label: "ambulance dispatcher", value: "ambulance dispatcher" },
	{ label: "ambulance driver", value: "ambulance driver" },
	{ label: "amusement machine servicer", value: "amusement machine servicer" },
	{ label: "anesthesiologist", value: "anesthesiologist" },
	{ label: "animal breeder", value: "animal breeder" },
	{ label: "animal control worker", value: "animal control worker" },
	{ label: "animal scientist", value: "animal scientist" },
	{ label: "animal trainer", value: "animal trainer" },
	{ label: "animator", value: "animator" },
	{ label: "answering service operator", value: "answering service operator" },
	{ label: "anthropologist", value: "anthropologist" },
	{ label: "apparel patternmaker", value: "apparel patternmaker" },
	{ label: "apparel worker", value: "apparel worker" },
	{ label: "arbitrator", value: "arbitrator" },
	{ label: "archeologist", value: "archeologist" },
	{ label: "architect", value: "architect" },
	{ label: "architectural drafter", value: "architectural drafter" },
	{ label: "architectural manager", value: "architectural manager" },
	{ label: "archivist", value: "archivist" },
	{ label: "art director", value: "art director" },
	{ label: "art teacher", value: "art teacher" },
	{ label: "artist", value: "artist" },
	{ label: "assembler", value: "assembler" },
	{ label: "astronomer", value: "astronomer" },
	{ label: "athlete", value: "athlete" },
	{ label: "athletic trainer", value: "athletic trainer" },
	{ label: "ATM machine repairer", value: "ATM machine repairer" },
	{ label: "atmospheric scientist", value: "atmospheric scientist" },
	{ label: "attendant", value: "attendant" },
	{
		label: "audio and video equipment technician",
		value: "audio and video equipment technician",
	},
	{
		label: "audio-visual and multimedia collections specialist",
		value: "audio-visual and multimedia collections specialist",
	},
	{ label: "audiologist", value: "audiologist" },
	{ label: "auditor", value: "auditor" },
	{ label: "author", value: "author" },
	{
		label: "auto damage insurance appraiser",
		value: "auto damage insurance appraiser",
	},
	{
		label: "automotive and watercraft service attendant",
		value: "automotive and watercraft service attendant",
	},
	{ label: "automotive glass installer", value: "automotive glass installer" },
	{ label: "automotive mechanic", value: "automotive mechanic" },
	{ label: "avionics technician", value: "avionics technician" },
	{ label: "back-end developer", value: "back-end developer" },
	{ label: "baggage porter", value: "baggage porter" },
	{ label: "bailiff", value: "bailiff" },
	{ label: "baker", value: "baker" },
	{ label: "barback", value: "barback" },
	{ label: "barber", value: "barber" },
	{ label: "bartender", value: "bartender" },
	{ label: "basic education teacher", value: "basic education teacher" },
	{
		label: "behavioral disorder counselor",
		value: "behavioral disorder counselor",
	},
	{ label: "bellhop", value: "bellhop" },
	{ label: "bench carpenter", value: "bench carpenter" },
	{ label: "bicycle repairer", value: "bicycle repairer" },
	{ label: "bill and account collector", value: "bill and account collector" },
	{ label: "billing and posting clerk", value: "billing and posting clerk" },
	{ label: "biochemist", value: "biochemist" },
	{ label: "biological technician", value: "biological technician" },
	{ label: "biomedical engineer", value: "biomedical engineer" },
	{ label: "biophysicist", value: "biophysicist" },
	{ label: "blaster", value: "blaster" },
	{ label: "blending machine operator", value: "blending machine operator" },
	{ label: "blockmason", value: "blockmason" },
	{ label: "boiler operator", value: "boiler operator" },
	{ label: "boilermaker", value: "boilermaker" },
	{ label: "bookkeeper", value: "bookkeeper" },
	{ label: "boring machine tool tender", value: "boring machine tool tender" },
	{ label: "brazer", value: "brazer" },
	{ label: "brickmason", value: "brickmason" },
	{ label: "bridge and lock tender", value: "bridge and lock tender" },
	{ label: "broadcast news analyst", value: "broadcast news analyst" },
	{ label: "broadcast technician", value: "broadcast technician" },
	{ label: "brokerage clerk", value: "brokerage clerk" },
	{ label: "budget analyst", value: "budget analyst" },
	{ label: "building inspector", value: "building inspector" },
	{ label: "bus mechanic", value: "bus mechanic" },
	{ label: "butcher", value: "butcher" },
	{ label: "buyer", value: "buyer" },
	{ label: "cabinetmaker", value: "cabinetmaker" },
	{ label: "cafeteria attendant", value: "cafeteria attendant" },
	{ label: "cafeteria cook", value: "cafeteria cook" },
	{ label: "camera operator", value: "camera operator" },
	{ label: "camera repairer", value: "camera repairer" },
	{ label: "cardiovascular technician", value: "cardiovascular technician" },
	{ label: "cargo agent", value: "cargo agent" },
	{ label: "carpenter", value: "carpenter" },
	{ label: "carpet installer", value: "carpet installer" },
	{ label: "cartographer", value: "cartographer" },
	{ label: "cashier", value: "cashier" },
	{ label: "caster", value: "caster" },
	{ label: "ceiling tile installer", value: "ceiling tile installer" },
	{
		label: "cellular equipment installer",
		value: "cellular equipment installer",
	},
	{ label: "cement mason", value: "cement mason" },
	{
		label: "channeling machine operator",
		value: "channeling machine operator",
	},
	{ label: "chauffeur", value: "chauffeur" },
	{ label: "checker", value: "checker" },
	{ label: "chef", value: "chef" },
	{ label: "chemical engineer", value: "chemical engineer" },
	{ label: "chemical plant operator", value: "chemical plant operator" },
	{ label: "chemist", value: "chemist" },
	{ label: "chemistry teacher", value: "chemistry teacher" },
	{ label: "chief executive", value: "chief executive" },
	{ label: "child social worker", value: "child social worker" },
	{ label: "childcare worker", value: "childcare worker" },
	{ label: "chiropractor", value: "chiropractor" },
	{ label: "choreographer", value: "choreographer" },
	{ label: "civil drafter", value: "civil drafter" },
	{ label: "civil engineer", value: "civil engineer" },
	{
		label: "civil engineering technician",
		value: "civil engineering technician",
	},
	{ label: "claims adjuster", value: "claims adjuster" },
	{ label: "claims examiner", value: "claims examiner" },
	{ label: "claims investigator", value: "claims investigator" },
	{ label: "cleaner", value: "cleaner" },
	{
		label: "clinical laboratory technician",
		value: "clinical laboratory technician",
	},
	{
		label: "clinical laboratory technologist",
		value: "clinical laboratory technologist",
	},
	{ label: "clinical psychologist", value: "clinical psychologist" },
	{ label: "coating worker", value: "coating worker" },
	{ label: "coatroom attendant", value: "coatroom attendant" },
	{ label: "coil finisher", value: "coil finisher" },
	{ label: "coil taper", value: "coil taper" },
	{ label: "coil winder", value: "coil winder" },
	{ label: "coin machine servicer", value: "coin machine servicer" },
	{ label: "commercial diver", value: "commercial diver" },
	{ label: "commercial pilot", value: "commercial pilot" },
	{ label: "commodities sales agent", value: "commodities sales agent" },
	{
		label: "communications equipment operator",
		value: "communications equipment operator",
	},
	{ label: "communications teacher", value: "communications teacher" },
	{
		label: "community association manager",
		value: "community association manager",
	},
	{ label: "community service manager", value: "community service manager" },
	{
		label: "compensation and benefits manager",
		value: "compensation and benefits manager",
	},
	{ label: "compliance officer", value: "compliance officer" },
	{ label: "composer", value: "composer" },
	{ label: "computer hardware engineer", value: "computer hardware engineer" },
	{ label: "computer network architect", value: "computer network architect" },
	{ label: "computer operator", value: "computer operator" },
	{ label: "computer programmer", value: "computer programmer" },
	{ label: "computer science teacher", value: "computer science teacher" },
	{
		label: "computer support specialist",
		value: "computer support specialist",
	},
	{
		label: "computer systems administrator",
		value: "computer systems administrator",
	},
	{ label: "computer systems analyst", value: "computer systems analyst" },
	{ label: "concierge", value: "concierge" },
	{ label: "conciliator", value: "conciliator" },
	{ label: "concrete finisher", value: "concrete finisher" },
	{
		label: "conservation science teacher",
		value: "conservation science teacher",
	},
	{ label: "conservation scientist", value: "conservation scientist" },
	{ label: "conservation worker", value: "conservation worker" },
	{ label: "conservator", value: "conservator" },
	{ label: "construction inspector", value: "construction inspector" },
	{ label: "construction manager", value: "construction manager" },
	{ label: "construction painter", value: "construction painter" },
	{ label: "construction worker", value: "construction worker" },
	{
		label: "continuous mining machine operator",
		value: "continuous mining machine operator",
	},
	{ label: "convention planner", value: "convention planner" },
	{ label: "conveyor operator", value: "conveyor operator" },
	{ label: "cook", value: "cook" },
	{ label: "cooling equipment operator", value: "cooling equipment operator" },
	{ label: "copy marker", value: "copy marker" },
	{ label: "correctional officer", value: "correctional officer" },
	{
		label: "correctional treatment specialist",
		value: "correctional treatment specialist",
	},
	{ label: "correspondence clerk", value: "correspondence clerk" },
	{ label: "correspondent", value: "correspondent" },
	{ label: "cosmetologist", value: "cosmetologist" },
	{ label: "cost estimator", value: "cost estimator" },
	{ label: "costume attendant", value: "costume attendant" },
	{ label: "counseling psychologist", value: "counseling psychologist" },
	{ label: "counselor", value: "counselor" },
	{ label: "courier", value: "courier" },
	{ label: "court reporter", value: "court reporter" },
	{ label: "craft artist", value: "craft artist" },
	{ label: "crane operator", value: "crane operator" },
	{ label: "credit analyst", value: "credit analyst" },
	{ label: "credit checker", value: "credit checker" },
	{ label: "credit counselor", value: "credit counselor" },
	{ label: "criminal investigator", value: "criminal investigator" },
	{ label: "criminal justice teacher", value: "criminal justice teacher" },
	{ label: "crossing guard", value: "crossing guard" },
	{ label: "curator", value: "curator" },
	{ label: "custom sewer", value: "custom sewer" },
	{
		label: "customer service representative",
		value: "customer service representative",
	},
	{ label: "cutter", value: "cutter" },
	{ label: "cutting machine operator", value: "cutting machine operator" },
	{ label: "dancer", value: "dancer" },
	{ label: "data entry keyer", value: "data entry keyer" },
	{ label: "database administrator", value: "database administrator" },
	{ label: "decorating worker", value: "decorating worker" },
	{ label: "delivery services driver", value: "delivery services driver" },
	{ label: "demonstrator", value: "demonstrator" },
	{ label: "dental assistant", value: "dental assistant" },
	{ label: "dental hygienist", value: "dental hygienist" },
	{
		label: "dental laboratory technician",
		value: "dental laboratory technician",
	},
	{ label: "dentist", value: "dentist" },
	{ label: "dermatologist", value: "dermatologist" },
	{ label: "derrick operator", value: "derrick operator" },
	{ label: "designer", value: "designer" },
	{ label: "desktop publisher", value: "desktop publisher" },
	{ label: "detective", value: "detective" },
	{ label: "developer", value: "developer" },
	{
		label: "diagnostic medical sonographer",
		value: "diagnostic medical sonographer",
	},
	{ label: "die maker", value: "die maker" },
	{ label: "diesel engine specialist", value: "diesel engine specialist" },
	{ label: "dietetic technician", value: "dietetic technician" },
	{ label: "dietitian", value: "dietitian" },
	{ label: "dinkey operator", value: "dinkey operator" },
	{ label: "director", value: "director" },
	{ label: "dishwasher", value: "dishwasher" },
	{ label: "dispatcher", value: "dispatcher" },
	{ label: "DJ", value: "DJ" },
	{ label: "doctor", value: "doctor" },
	{ label: "door-to-door sales worker", value: "door-to-door sales worker" },
	{ label: "drafter", value: "drafter" },
	{ label: "dragline operator", value: "dragline operator" },
	{ label: "drama teacher", value: "drama teacher" },
	{ label: "dredge operator", value: "dredge operator" },
	{ label: "dressing room attendant", value: "dressing room attendant" },
	{ label: "dressmaker", value: "dressmaker" },
	{ label: "drier operator", value: "drier operator" },
	{
		label: "drilling machine tool operator",
		value: "drilling machine tool operator",
	},
	{ label: "dry-cleaning worker", value: "dry-cleaning worker" },
	{ label: "drywall installer", value: "drywall installer" },
	{ label: "dyeing machine operator", value: "dyeing machine operator" },
	{ label: "earth driller", value: "earth driller" },
	{ label: "economics teacher", value: "economics teacher" },
	{ label: "economist", value: "economist" },
	{ label: "editor", value: "editor" },
	{ label: "education administrator", value: "education administrator" },
	{ label: "electric motor repairer", value: "electric motor repairer" },
	{
		label: "electrical electronics drafter",
		value: "electrical electronics drafter",
	},
	{ label: "electrical engineer", value: "electrical engineer" },
	{
		label: "electrical equipment assembler",
		value: "electrical equipment assembler",
	},
	{ label: "electrical installer", value: "electrical installer" },
	{
		label: "electrical power-line installer",
		value: "electrical power-line installer",
	},
	{ label: "electrician", value: "electrician" },
	{
		label: "electro-mechanical technician",
		value: "electro-mechanical technician",
	},
	{ label: "elementary school teacher", value: "elementary school teacher" },
	{ label: "elevator installer", value: "elevator installer" },
	{ label: "elevator repairer", value: "elevator repairer" },
	{ label: "embalmer", value: "embalmer" },
	{
		label: "emergency management director",
		value: "emergency management director",
	},
	{
		label: "emergency medical technician",
		value: "emergency medical technician",
	},
	{ label: "engine assembler", value: "engine assembler" },
	{ label: "engineer", value: "engineer" },
	{ label: "engineering manager", value: "engineering manager" },
	{ label: "engineering teacher", value: "engineering teacher" },
	{ label: "english language teacher", value: "english language teacher" },
	{ label: "engraver", value: "engraver" },
	{ label: "entertainment attendant", value: "entertainment attendant" },
	{ label: "environmental engineer", value: "environmental engineer" },
	{
		label: "environmental science teacher",
		value: "environmental science teacher",
	},
	{ label: "environmental scientist", value: "environmental scientist" },
	{ label: "epidemiologist", value: "epidemiologist" },
	{ label: "escort", value: "escort" },
	{ label: "etcher", value: "etcher" },
	{ label: "event planner", value: "event planner" },
	{ label: "excavating operator", value: "excavating operator" },
	{
		label: "executive administrative assistant",
		value: "executive administrative assistant",
	},
	{ label: "executive secretary", value: "executive secretary" },
	{ label: "exhibit designer", value: "exhibit designer" },
	{ label: "expediting clerk", value: "expediting clerk" },
	{ label: "explosives worker", value: "explosives worker" },
	{ label: "extraction worker", value: "extraction worker" },
	{ label: "fabric mender", value: "fabric mender" },
	{ label: "fabric patternmaker", value: "fabric patternmaker" },
	{ label: "fabricator", value: "fabricator" },
	{ label: "faller", value: "faller" },
	{ label: "family practitioner", value: "family practitioner" },
	{ label: "family social worker", value: "family social worker" },
	{ label: "family therapist", value: "family therapist" },
	{ label: "farm advisor", value: "farm advisor" },
	{ label: "farm equipment mechanic", value: "farm equipment mechanic" },
	{ label: "farm labor contractor", value: "farm labor contractor" },
	{ label: "farmer", value: "farmer" },
	{ label: "farmworker", value: "farmworker" },
	{ label: "fashion designer", value: "fashion designer" },
	{ label: "fast food cook", value: "fast food cook" },
	{ label: "fence erector", value: "fence erector" },
	{ label: "fiberglass fabricator", value: "fiberglass fabricator" },
	{ label: "fiberglass laminator", value: "fiberglass laminator" },
	{ label: "file clerk", value: "file clerk" },
	{ label: "filling machine operator", value: "filling machine operator" },
	{ label: "film and video editor", value: "film and video editor" },
	{ label: "financial analyst", value: "financial analyst" },
	{ label: "financial examiner", value: "financial examiner" },
	{ label: "financial manager", value: "financial manager" },
	{
		label: "financial services sales agent",
		value: "financial services sales agent",
	},
	{ label: "fine artist", value: "fine artist" },
	{
		label: "fire alarm system installer",
		value: "fire alarm system installer",
	},
	{ label: "fire dispatcher", value: "fire dispatcher" },
	{ label: "fire inspector", value: "fire inspector" },
	{ label: "fire investigator", value: "fire investigator" },
	{ label: "firefighter", value: "firefighter" },
	{ label: "fish and game warden", value: "fish and game warden" },
	{ label: "fish cutter", value: "fish cutter" },
	{ label: "fish trimmer", value: "fish trimmer" },
	{ label: "fisher", value: "fisher" },
	{ label: "fitness studies teacher", value: "fitness studies teacher" },
	{ label: "fitness trainer", value: "fitness trainer" },
	{ label: "flight attendant", value: "flight attendant" },
	{ label: "floor finisher", value: "floor finisher" },
	{ label: "floor layer", value: "floor layer" },
	{ label: "floor sander", value: "floor sander" },
	{ label: "floral designer", value: "floral designer" },
	{ label: "food batchmaker", value: "food batchmaker" },
	{
		label: "food cooking machine operator",
		value: "food cooking machine operator",
	},
	{ label: "food preparation worker", value: "food preparation worker" },
	{ label: "food science technician", value: "food science technician" },
	{ label: "food scientist", value: "food scientist" },
	{ label: "food server", value: "food server" },
	{ label: "food service manager", value: "food service manager" },
	{ label: "food technologist", value: "food technologist" },
	{ label: "foreign language teacher", value: "foreign language teacher" },
	{ label: "foreign literature teacher", value: "foreign literature teacher" },
	{
		label: "forensic science technician",
		value: "forensic science technician",
	},
	{ label: "forest fire inspector", value: "forest fire inspector" },
	{
		label: "forest fire prevention specialist",
		value: "forest fire prevention specialist",
	},
	{ label: "forest worker", value: "forest worker" },
	{ label: "forester", value: "forester" },
	{ label: "forestry teacher", value: "forestry teacher" },
	{ label: "forging machine setter", value: "forging machine setter" },
	{ label: "foundry coremaker", value: "foundry coremaker" },
	{ label: "freight agent", value: "freight agent" },
	{ label: "freight mover", value: "freight mover" },
	{ label: "front-end developer", value: "front-end developer" },
	{ label: "fundraising manager", value: "fundraising manager" },
	{ label: "funeral attendant", value: "funeral attendant" },
	{ label: "funeral director", value: "funeral director" },
	{ label: "funeral service manager", value: "funeral service manager" },
	{ label: "furnace operator", value: "furnace operator" },
	{ label: "furnishings worker", value: "furnishings worker" },
	{ label: "furniture finisher", value: "furniture finisher" },
	{ label: "gaming booth cashier", value: "gaming booth cashier" },
	{ label: "gaming cage worker", value: "gaming cage worker" },
	{ label: "gaming change person", value: "gaming change person" },
	{ label: "gaming dealer", value: "gaming dealer" },
	{ label: "gaming investigator", value: "gaming investigator" },
	{ label: "gaming manager", value: "gaming manager" },
	{
		label: "gaming surveillance officer",
		value: "gaming surveillance officer",
	},
	{ label: "garment mender", value: "garment mender" },
	{ label: "garment presser", value: "garment presser" },
	{ label: "gas compressor", value: "gas compressor" },
	{ label: "gas plant operator", value: "gas plant operator" },
	{
		label: "gas pumping station operator",
		value: "gas pumping station operator",
	},
	{ label: "general manager", value: "general manager" },
	{ label: "general practitioner", value: "general practitioner" },
	{ label: "geographer", value: "geographer" },
	{ label: "geography teacher", value: "geography teacher" },
	{ label: "geological engineer", value: "geological engineer" },
	{ label: "geological technician", value: "geological technician" },
	{ label: "geoscientist", value: "geoscientist" },
	{ label: "glazier", value: "glazier" },
	{
		label: "government program eligibility interviewer",
		value: "government program eligibility interviewer",
	},
	{
		label: "graduate teaching assistant",
		value: "graduate teaching assistant",
	},
	{ label: "graphic designer", value: "graphic designer" },
	{ label: "groundskeeper", value: "groundskeeper" },
	{ label: "groundskeeping worker", value: "groundskeeping worker" },
	{ label: "gynecologist", value: "gynecologist" },
	{ label: "hairdresser", value: "hairdresser" },
	{ label: "hairstylist", value: "hairstylist" },
	{ label: "hand grinding worker", value: "hand grinding worker" },
	{ label: "hand laborer", value: "hand laborer" },
	{ label: "hand packager", value: "hand packager" },
	{ label: "hand packer", value: "hand packer" },
	{ label: "hand polishing worker", value: "hand polishing worker" },
	{ label: "hand sewer", value: "hand sewer" },
	{
		label: "hazardous materials removal worker",
		value: "hazardous materials removal worker",
	},
	{ label: "head cook", value: "head cook" },
	{ label: "health and safety engineer", value: "health and safety engineer" },
	{ label: "health educator", value: "health educator" },
	{
		label: "health information technician",
		value: "health information technician",
	},
	{ label: "health services manager", value: "health services manager" },
	{ label: "health specialties teacher", value: "health specialties teacher" },
	{ label: "healthcare social worker", value: "healthcare social worker" },
	{ label: "hearing officer", value: "hearing officer" },
	{
		label: "heat treating equipment setter",
		value: "heat treating equipment setter",
	},
	{ label: "heating installer", value: "heating installer" },
	{ label: "heating mechanic", value: "heating mechanic" },
	{ label: "heavy truck driver", value: "heavy truck driver" },
	{ label: "highway maintenance worker", value: "highway maintenance worker" },
	{ label: "historian", value: "historian" },
	{ label: "history teacher", value: "history teacher" },
	{ label: "hoist and winch operator", value: "hoist and winch operator" },
	{ label: "home appliance repairer", value: "home appliance repairer" },
	{ label: "home economics teacher", value: "home economics teacher" },
	{
		label: "home entertainment installer",
		value: "home entertainment installer",
	},
	{ label: "home health aide", value: "home health aide" },
	{ label: "home management advisor", value: "home management advisor" },
	{ label: "host", value: "host" },
	{ label: "hostess", value: "hostess" },
	{ label: "hostler", value: "hostler" },
	{ label: "hotel desk clerk", value: "hotel desk clerk" },
	{ label: "housekeeping cleaner", value: "housekeeping cleaner" },
	{ label: "human resources assistant", value: "human resources assistant" },
	{ label: "human resources manager", value: "human resources manager" },
	{ label: "human service assistant", value: "human service assistant" },
	{ label: "hunter", value: "hunter" },
	{ label: "hydrologist", value: "hydrologist" },
	{ label: "illustrator", value: "illustrator" },
	{ label: "industrial designer", value: "industrial designer" },
	{ label: "industrial engineer", value: "industrial engineer" },
	{
		label: "industrial engineering technician",
		value: "industrial engineering technician",
	},
	{
		label: "industrial machinery mechanic",
		value: "industrial machinery mechanic",
	},
	{
		label: "industrial production manager",
		value: "industrial production manager",
	},
	{ label: "industrial truck operator", value: "industrial truck operator" },
	{
		label: "industrial-organizational psychologist",
		value: "industrial-organizational psychologist",
	},
	{ label: "information clerk", value: "information clerk" },
	{
		label: "information research scientist",
		value: "information research scientist",
	},
	{
		label: "information security analyst",
		value: "information security analyst",
	},
	{
		label: "information systems manager",
		value: "information systems manager",
	},
	{ label: "inspector", value: "inspector" },
	{ label: "instructional coordinator", value: "instructional coordinator" },
	{ label: "instructor", value: "instructor" },
	{ label: "insulation worker", value: "insulation worker" },
	{ label: "insurance claims clerk", value: "insurance claims clerk" },
	{ label: "insurance sales agent", value: "insurance sales agent" },
	{ label: "insurance underwriter", value: "insurance underwriter" },
	{ label: "intercity bus driver", value: "intercity bus driver" },
	{ label: "interior designer", value: "interior designer" },
	{ label: "internist", value: "internist" },
	{ label: "interpreter", value: "interpreter" },
	{ label: "interviewer", value: "interviewer" },
	{ label: "investigator", value: "investigator" },
	{ label: "jailer", value: "jailer" },
	{ label: "janitor", value: "janitor" },
	{ label: "jeweler", value: "jeweler" },
	{ label: "judge", value: "judge" },
	{ label: "judicial law clerk", value: "judicial law clerk" },
	{ label: "kettle operator", value: "kettle operator" },
	{ label: "kiln operator", value: "kiln operator" },
	{ label: "kindergarten teacher", value: "kindergarten teacher" },
	{
		label: "laboratory animal caretaker",
		value: "laboratory animal caretaker",
	},
	{ label: "landscape architect", value: "landscape architect" },
	{ label: "landscaping worker", value: "landscaping worker" },
	{ label: "lathe setter", value: "lathe setter" },
	{ label: "laundry worker", value: "laundry worker" },
	{ label: "law enforcement teacher", value: "law enforcement teacher" },
	{ label: "law teacher", value: "law teacher" },
	{ label: "lawyer", value: "lawyer" },
	{ label: "layout worker", value: "layout worker" },
	{ label: "leather worker", value: "leather worker" },
	{ label: "legal assistant", value: "legal assistant" },
	{ label: "legal secretary", value: "legal secretary" },
	{ label: "legislator", value: "legislator" },
	{ label: "librarian", value: "librarian" },
	{ label: "library assistant", value: "library assistant" },
	{ label: "library science teacher", value: "library science teacher" },
	{ label: "library technician", value: "library technician" },
	{ label: "licensed practical nurse", value: "licensed practical nurse" },
	{ label: "licensed vocational nurse", value: "licensed vocational nurse" },
	{ label: "life scientist", value: "life scientist" },
	{ label: "lifeguard", value: "lifeguard" },
	{ label: "light truck driver", value: "light truck driver" },
	{ label: "line installer", value: "line installer" },
	{ label: "literacy teacher", value: "literacy teacher" },
	{ label: "literature teacher", value: "literature teacher" },
	{ label: "loading machine operator", value: "loading machine operator" },
	{ label: "loan clerk", value: "loan clerk" },
	{ label: "loan interviewer", value: "loan interviewer" },
	{ label: "loan officer", value: "loan officer" },
	{ label: "lobby attendant", value: "lobby attendant" },
	{ label: "locker room attendant", value: "locker room attendant" },
	{ label: "locksmith", value: "locksmith" },
	{ label: "locomotive engineer", value: "locomotive engineer" },
	{ label: "locomotive firer", value: "locomotive firer" },
	{ label: "lodging manager", value: "lodging manager" },
	{ label: "log grader", value: "log grader" },
	{ label: "logging equipment operator", value: "logging equipment operator" },
	{ label: "logistician", value: "logistician" },
	{ label: "machine feeder", value: "machine feeder" },
	{ label: "machinist", value: "machinist" },
	{ label: "magistrate judge", value: "magistrate judge" },
	{ label: "magistrate", value: "magistrate" },
	{ label: "maid", value: "maid" },
	{ label: "mail clerk", value: "mail clerk" },
	{ label: "mail machine operator", value: "mail machine operator" },
	{ label: "mail superintendent", value: "mail superintendent" },
	{ label: "maintenance painter", value: "maintenance painter" },
	{ label: "maintenance worker", value: "maintenance worker" },
	{ label: "makeup artist", value: "makeup artist" },
	{ label: "management analyst", value: "management analyst" },
	{ label: "manicurist", value: "manicurist" },
	{
		label: "manufactured building installer",
		value: "manufactured building installer",
	},
	{ label: "mapping technician", value: "mapping technician" },
	{ label: "marble setter", value: "marble setter" },
	{ label: "marine engineer", value: "marine engineer" },
	{ label: "marine oiler", value: "marine oiler" },
	{ label: "market research analyst", value: "market research analyst" },
	{ label: "marketing manager", value: "marketing manager" },
	{ label: "marketing specialist", value: "marketing specialist" },
	{ label: "marriage therapist", value: "marriage therapist" },
	{ label: "massage therapist", value: "massage therapist" },
	{ label: "material mover", value: "material mover" },
	{ label: "materials engineer", value: "materials engineer" },
	{ label: "materials scientist", value: "materials scientist" },
	{
		label: "mathematical science teacher",
		value: "mathematical science teacher",
	},
	{ label: "mathematical technician", value: "mathematical technician" },
	{ label: "mathematician", value: "mathematician" },
	{ label: "maxillofacial surgeon", value: "maxillofacial surgeon" },
	{ label: "measurer", value: "measurer" },
	{ label: "meat cutter", value: "meat cutter" },
	{ label: "meat packer", value: "meat packer" },
	{ label: "meat trimmer", value: "meat trimmer" },
	{ label: "mechanical door repairer", value: "mechanical door repairer" },
	{ label: "mechanical drafter", value: "mechanical drafter" },
	{ label: "mechanical engineer", value: "mechanical engineer" },
	{
		label: "mechanical engineering technician",
		value: "mechanical engineering technician",
	},
	{ label: "mediator", value: "mediator" },
	{
		label: "medical appliance technician",
		value: "medical appliance technician",
	},
	{ label: "medical assistant", value: "medical assistant" },
	{ label: "medical equipment preparer", value: "medical equipment preparer" },
	{ label: "medical equipment repairer", value: "medical equipment repairer" },
	{
		label: "medical laboratory technician",
		value: "medical laboratory technician",
	},
	{
		label: "medical laboratory technologist",
		value: "medical laboratory technologist",
	},
	{ label: "medical records technician", value: "medical records technician" },
	{ label: "medical scientist", value: "medical scientist" },
	{ label: "medical secretary", value: "medical secretary" },
	{ label: "medical services manager", value: "medical services manager" },
	{ label: "medical transcriptionist", value: "medical transcriptionist" },
	{ label: "meeting planner", value: "meeting planner" },
	{ label: "mental health counselor", value: "mental health counselor" },
	{
		label: "mental health social worker",
		value: "mental health social worker",
	},
	{ label: "merchandise displayer", value: "merchandise displayer" },
	{ label: "messenger", value: "messenger" },
	{ label: "metal caster", value: "metal caster" },
	{ label: "metal patternmaker", value: "metal patternmaker" },
	{ label: "metal pickling operator", value: "metal pickling operator" },
	{ label: "metal pourer", value: "metal pourer" },
	{ label: "metal worker", value: "metal worker" },
	{
		label: "metal-refining furnace operator",
		value: "metal-refining furnace operator",
	},
	{
		label: "metal-refining furnace tender",
		value: "metal-refining furnace tender",
	},
	{ label: "meter reader", value: "meter reader" },
	{ label: "microbiologist", value: "microbiologist" },
	{ label: "middle school teacher", value: "middle school teacher" },
	{ label: "milling machine setter", value: "milling machine setter" },
	{ label: "millwright", value: "millwright" },
	{
		label: "mine cutting machine operator",
		value: "mine cutting machine operator",
	},
	{ label: "mine shuttle car operator", value: "mine shuttle car operator" },
	{ label: "mining engineer", value: "mining engineer" },
	{ label: "mining safety engineer", value: "mining safety engineer" },
	{ label: "mining safety inspector", value: "mining safety inspector" },
	{
		label: "mining service unit operator",
		value: "mining service unit operator",
	},
	{ label: "mixing machine setter", value: "mixing machine setter" },
	{
		label: "mobile heavy equipment mechanic",
		value: "mobile heavy equipment mechanic",
	},
	{ label: "mobile home installer", value: "mobile home installer" },
	{ label: "model maker", value: "model maker" },
	{ label: "model", value: "model" },
	{ label: "molder", value: "molder" },
	{ label: "mortician", value: "mortician" },
	{ label: "motel desk clerk", value: "motel desk clerk" },
	{
		label: "motion picture projectionist",
		value: "motion picture projectionist",
	},
	{ label: "motorboat mechanic", value: "motorboat mechanic" },
	{ label: "motorboat operator", value: "motorboat operator" },
	{
		label: "motorboat service technician",
		value: "motorboat service technician",
	},
	{ label: "motorcycle mechanic", value: "motorcycle mechanic" },
	{ label: "movers", value: "movers" },
	{ label: "multimedia artist", value: "multimedia artist" },
	{ label: "museum technician", value: "museum technician" },
	{ label: "music director", value: "music director" },
	{ label: "music teacher", value: "music teacher" },
	{
		label: "musical instrument repairer",
		value: "musical instrument repairer",
	},
	{ label: "musician", value: "musician" },
	{ label: "natural sciences manager", value: "natural sciences manager" },
	{ label: "naval architect", value: "naval architect" },
	{
		label: "network systems administrator",
		value: "network systems administrator",
	},
	{ label: "new accounts clerk", value: "new accounts clerk" },
	{ label: "news vendor", value: "news vendor" },
	{ label: "nonfarm animal caretaker", value: "nonfarm animal caretaker" },
	{ label: "nuclear engineer", value: "nuclear engineer" },
	{
		label: "nuclear medicine technologist",
		value: "nuclear medicine technologist",
	},
	{
		label: "nuclear power reactor operator",
		value: "nuclear power reactor operator",
	},
	{ label: "nuclear technician", value: "nuclear technician" },
	{ label: "nursing aide", value: "nursing aide" },
	{ label: "nursing instructor", value: "nursing instructor" },
	{ label: "nursing teacher", value: "nursing teacher" },
	{ label: "nutritionist", value: "nutritionist" },
	{ label: "obstetrician", value: "obstetrician" },
	{
		label: "occupational health and safety specialist",
		value: "occupational health and safety specialist",
	},
	{
		label: "occupational health and safety technician",
		value: "occupational health and safety technician",
	},
	{ label: "occupational therapist", value: "occupational therapist" },
	{ label: "occupational therapy aide", value: "occupational therapy aide" },
	{
		label: "occupational therapy assistant",
		value: "occupational therapy assistant",
	},
	{ label: "offbearer", value: "offbearer" },
	{ label: "office clerk", value: "office clerk" },
	{ label: "office machine operator", value: "office machine operator" },
	{ label: "operating engineer", value: "operating engineer" },
	{ label: "operations manager", value: "operations manager" },
	{
		label: "operations research analyst",
		value: "operations research analyst",
	},
	{
		label: "ophthalmic laboratory technician",
		value: "ophthalmic laboratory technician",
	},
	{ label: "optician", value: "optician" },
	{ label: "optometrist", value: "optometrist" },
	{ label: "oral surgeon", value: "oral surgeon" },
	{ label: "order clerk", value: "order clerk" },
	{ label: "order filler", value: "order filler" },
	{ label: "orderly", value: "orderly" },
	{ label: "ordnance handling expert", value: "ordnance handling expert" },
	{ label: "orthodontist", value: "orthodontist" },
	{ label: "orthotist", value: "orthotist" },
	{
		label: "outdoor power equipment mechanic",
		value: "outdoor power equipment mechanic",
	},
	{ label: "oven operator", value: "oven operator" },
	{ label: "packaging machine operator", value: "packaging machine operator" },
	{ label: "painter ", value: "painter " },
	{ label: "painting worker", value: "painting worker" },
	{ label: "paper goods machine setter", value: "paper goods machine setter" },
	{ label: "paperhanger", value: "paperhanger" },
	{ label: "paralegal", value: "paralegal" },
	{ label: "paramedic", value: "paramedic" },
	{ label: "parking enforcement worker", value: "parking enforcement worker" },
	{ label: "parking lot attendant", value: "parking lot attendant" },
	{ label: "parts salesperson", value: "parts salesperson" },
	{ label: "paving equipment operator", value: "paving equipment operator" },
	{ label: "payroll clerk", value: "payroll clerk" },
	{ label: "pediatrician", value: "pediatrician" },
	{ label: "pedicurist", value: "pedicurist" },
	{ label: "personal care aide", value: "personal care aide" },
	{ label: "personal chef", value: "personal chef" },
	{ label: "personal financial advisor", value: "personal financial advisor" },
	{ label: "personal trainer", value: "personal trainer" },
	{ label: "pest control worker", value: "pest control worker" },
	{ label: "pesticide applicator", value: "pesticide applicator" },
	{ label: "pesticide handler", value: "pesticide handler" },
	{ label: "pesticide sprayer", value: "pesticide sprayer" },
	{ label: "petroleum engineer", value: "petroleum engineer" },
	{ label: "petroleum gauger", value: "petroleum gauger" },
	{
		label: "petroleum pump system operator",
		value: "petroleum pump system operator",
	},
	{
		label: "petroleum refinery operator",
		value: "petroleum refinery operator",
	},
	{ label: "petroleum technician", value: "petroleum technician" },
	{ label: "pharmacist", value: "pharmacist" },
	{ label: "pharmacy aide", value: "pharmacy aide" },
	{ label: "pharmacy technician", value: "pharmacy technician" },
	{ label: "philosophy teacher", value: "philosophy teacher" },
	{ label: "photogrammetrist", value: "photogrammetrist" },
	{ label: "photographer", value: "photographer" },
	{
		label: "photographic process worker",
		value: "photographic process worker",
	},
	{
		label: "photographic processing machine operator",
		value: "photographic processing machine operator",
	},
	{ label: "physical therapist aide", value: "physical therapist aide" },
	{
		label: "physical therapist assistant",
		value: "physical therapist assistant",
	},
	{ label: "physical therapist", value: "physical therapist" },
	{ label: "physician assistant", value: "physician assistant" },
	{ label: "physician", value: "physician" },
	{ label: "physicist", value: "physicist" },
	{ label: "physics teacher", value: "physics teacher" },
	{ label: "pile-driver operator", value: "pile-driver operator" },
	{ label: "pipefitter", value: "pipefitter" },
	{ label: "pipelayer", value: "pipelayer" },
	{ label: "planing machine operator", value: "planing machine operator" },
	{ label: "planning clerk", value: "planning clerk" },
	{ label: "plant operator", value: "plant operator" },
	{ label: "plant scientist", value: "plant scientist" },
	{ label: "plasterer", value: "plasterer" },
	{ label: "plastic patternmaker", value: "plastic patternmaker" },
	{ label: "plastic worker", value: "plastic worker" },
	{ label: "plumber", value: "plumber" },
	{ label: "podiatrist", value: "podiatrist" },
	{ label: "police dispatcher", value: "police dispatcher" },
	{ label: "police officer", value: "police officer" },
	{ label: "policy processing clerk", value: "policy processing clerk" },
	{ label: "political science teacher", value: "political science teacher" },
	{ label: "political scientist", value: "political scientist" },
	{ label: "postal service clerk", value: "postal service clerk" },
	{
		label: "postal service mail carrier",
		value: "postal service mail carrier",
	},
	{
		label: "postal service mail processing machine operator",
		value: "postal service mail processing machine operator",
	},
	{
		label: "postal service mail processor",
		value: "postal service mail processor",
	},
	{ label: "postal service mail sorter", value: "postal service mail sorter" },
	{ label: "postmaster", value: "postmaster" },
	{ label: "postsecondary teacher", value: "postsecondary teacher" },
	{ label: "poultry cutter", value: "poultry cutter" },
	{ label: "poultry trimmer", value: "poultry trimmer" },
	{ label: "power dispatcher", value: "power dispatcher" },
	{ label: "power distributor", value: "power distributor" },
	{ label: "power plant operator", value: "power plant operator" },
	{ label: "power tool repairer", value: "power tool repairer" },
	{ label: "precious stone worker", value: "precious stone worker" },
	{
		label: "precision instrument repairer",
		value: "precision instrument repairer",
	},
	{ label: "prepress technician", value: "prepress technician" },
	{ label: "preschool teacher", value: "preschool teacher" },
	{ label: "priest", value: "priest" },
	{ label: "print binding worker", value: "print binding worker" },
	{ label: "printing press operator", value: "printing press operator" },
	{ label: "private detective", value: "private detective" },
	{ label: "probation officer", value: "probation officer" },
	{ label: "procurement clerk", value: "procurement clerk" },
	{ label: "producer", value: "producer" },
	{ label: "product promoter", value: "product promoter" },
	{ label: "product manager", value: "product manager" },
	{ label: "production clerk", value: "production clerk" },
	{ label: "production occupation", value: "production occupation" },
	{ label: "proofreader", value: "proofreader" },
	{ label: "property manager", value: "property manager" },
	{ label: "prosthetist", value: "prosthetist" },
	{ label: "prosthodontist", value: "prosthodontist" },
	{ label: "psychiatric aide", value: "psychiatric aide" },
	{ label: "psychiatric technician", value: "psychiatric technician" },
	{ label: "psychiatrist", value: "psychiatrist" },
	{ label: "psychologist", value: "psychologist" },
	{ label: "psychology teacher", value: "psychology teacher" },
	{ label: "public relations manager", value: "public relations manager" },
	{
		label: "public relations specialist",
		value: "public relations specialist",
	},
	{ label: "pump operator", value: "pump operator" },
	{ label: "purchasing agent", value: "purchasing agent" },
	{ label: "purchasing manager", value: "purchasing manager" },
	{ label: "radiation therapist", value: "radiation therapist" },
	{ label: "radio announcer", value: "radio announcer" },
	{ label: "radio equipment installer", value: "radio equipment installer" },
	{ label: "radio operator", value: "radio operator" },
	{ label: "radiologic technician", value: "radiologic technician" },
	{ label: "radiologic technologist", value: "radiologic technologist" },
	{ label: "rail car repairer", value: "rail car repairer" },
	{ label: "rail transportation worker", value: "rail transportation worker" },
	{ label: "rail yard engineer", value: "rail yard engineer" },
	{
		label: "rail-track laying equipment operator",
		value: "rail-track laying equipment operator",
	},
	{ label: "railroad brake operator", value: "railroad brake operator" },
	{ label: "railroad conductor", value: "railroad conductor" },
	{ label: "railroad police", value: "railroad police" },
	{ label: "rancher", value: "rancher" },
	{ label: "real estate appraiser", value: "real estate appraiser" },
	{ label: "real estate broker", value: "real estate broker" },
	{ label: "real estate manager", value: "real estate manager" },
	{ label: "real estate sales agent", value: "real estate sales agent" },
	{ label: "receiving clerk", value: "receiving clerk" },
	{ label: "receptionist", value: "receptionist" },
	{ label: "record clerk", value: "record clerk" },
	{ label: "recreation teacher", value: "recreation teacher" },
	{ label: "recreation worker", value: "recreation worker" },
	{ label: "recreational therapist", value: "recreational therapist" },
	{
		label: "recreational vehicle service technician",
		value: "recreational vehicle service technician",
	},
	{
		label: "recyclable material collector",
		value: "recyclable material collector",
	},
	{ label: "referee", value: "referee" },
	{
		label: "refractory materials repairer",
		value: "refractory materials repairer",
	},
	{ label: "refrigeration installer", value: "refrigeration installer" },
	{ label: "refrigeration mechanic", value: "refrigeration mechanic" },
	{ label: "refuse collector", value: "refuse collector" },
	{ label: "regional planner", value: "regional planner" },
	{ label: "registered nurse", value: "registered nurse" },
	{ label: "rehabilitation counselor", value: "rehabilitation counselor" },
	{ label: "reinforcing iron worker", value: "reinforcing iron worker" },
	{ label: "reinforcing rebar worker", value: "reinforcing rebar worker" },
	{ label: "religion teacher", value: "religion teacher" },
	{
		label: "religious activities director",
		value: "religious activities director",
	},
	{ label: "religious worker", value: "religious worker" },
	{ label: "rental clerk", value: "rental clerk" },
	{ label: "repair worker", value: "repair worker" },
	{ label: "reporter", value: "reporter" },
	{ label: "residential advisor", value: "residential advisor" },
	{ label: "resort desk clerk", value: "resort desk clerk" },
	{ label: "respiratory therapist", value: "respiratory therapist" },
	{
		label: "respiratory therapy technician",
		value: "respiratory therapy technician",
	},
	{ label: "retail buyer", value: "retail buyer" },
	{ label: "retail salesperson", value: "retail salesperson" },
	{ label: "revenue agent", value: "revenue agent" },
	{ label: "rigger", value: "rigger" },
	{ label: "rock splitter", value: "rock splitter" },
	{ label: "rolling machine tender", value: "rolling machine tender" },
	{ label: "roof bolter", value: "roof bolter" },
	{ label: "roofer", value: "roofer" },
	{ label: "rotary drill operator", value: "rotary drill operator" },
	{ label: "roustabout", value: "roustabout" },
	{ label: "safe repairer", value: "safe repairer" },
	{ label: "sailor", value: "sailor" },
	{ label: "sales engineer", value: "sales engineer" },
	{ label: "sales manager", value: "sales manager" },
	{ label: "sales representative", value: "sales representative" },
	{ label: "sampler", value: "sampler" },
	{ label: "sawing machine operator", value: "sawing machine operator" },
	{ label: "scaler", value: "scaler" },
	{ label: "school bus driver", value: "school bus driver" },
	{ label: "school psychologist", value: "school psychologist" },
	{ label: "school social worker", value: "school social worker" },
	{ label: "scout leader", value: "scout leader" },
	{ label: "sculptor", value: "sculptor" },
	{
		label: "secondary education teacher",
		value: "secondary education teacher",
	},
	{ label: "secondary school teacher", value: "secondary school teacher" },
	{ label: "secretary", value: "secretary" },
	{ label: "securities sales agent", value: "securities sales agent" },
	{ label: "security guard", value: "security guard" },
	{ label: "security system installer", value: "security system installer" },
	{ label: "segmental paver", value: "segmental paver" },
	{
		label: "self-enrichment education teacher",
		value: "self-enrichment education teacher",
	},
	{ label: "semiconductor processor", value: "semiconductor processor" },
	{ label: "septic tank servicer", value: "septic tank servicer" },
	{ label: "set designer", value: "set designer" },
	{ label: "sewer pipe cleaner", value: "sewer pipe cleaner" },
	{ label: "sewing machine operator", value: "sewing machine operator" },
	{ label: "shampooer", value: "shampooer" },
	{ label: "shaper", value: "shaper" },
	{ label: "sheet metal worker", value: "sheet metal worker" },
	{ label: "sheriff's patrol officer", value: "sheriff's patrol officer" },
	{ label: "ship captain", value: "ship captain" },
	{ label: "ship engineer", value: "ship engineer" },
	{ label: "ship loader", value: "ship loader" },
	{ label: "shipmate", value: "shipmate" },
	{ label: "shipping clerk", value: "shipping clerk" },
	{ label: "shoe machine operator", value: "shoe machine operator" },
	{ label: "shoe worker", value: "shoe worker" },
	{ label: "short order cook", value: "short order cook" },
	{ label: "signal operator", value: "signal operator" },
	{ label: "signal repairer", value: "signal repairer" },
	{ label: "singer", value: "singer" },
	{ label: "ski patrol", value: "ski patrol" },
	{ label: "skincare specialist", value: "skincare specialist" },
	{ label: "slaughterer", value: "slaughterer" },
	{ label: "slicing machine tender", value: "slicing machine tender" },
	{ label: "slot supervisor", value: "slot supervisor" },
	{
		label: "social science research assistant",
		value: "social science research assistant",
	},
	{ label: "social sciences teacher", value: "social sciences teacher" },
	{ label: "social scientist", value: "social scientist" },
	{ label: "social service assistant", value: "social service assistant" },
	{ label: "social service manager", value: "social service manager" },
	{ label: "social work teacher", value: "social work teacher" },
	{ label: "social worker", value: "social worker" },
	{ label: "sociologist", value: "sociologist" },
	{ label: "sociology teacher", value: "sociology teacher" },
	{ label: "software developer", value: "software developer" },
	{ label: "software engineer", value: "software engineer" },
	{ label: "soil scientist", value: "soil scientist" },
	{ label: "solderer", value: "solderer" },
	{ label: "sorter", value: "sorter" },
	{
		label: "sound engineering technician",
		value: "sound engineering technician",
	},
	{ label: "space scientist", value: "space scientist" },
	{ label: "special education teacher", value: "special education teacher" },
	{
		label: "speech-language pathologist",
		value: "speech-language pathologist",
	},
	{ label: "sports book runner", value: "sports book runner" },
	{ label: "sports entertainer", value: "sports entertainer" },
	{ label: "sports performer", value: "sports performer" },
	{ label: "stationary engineer", value: "stationary engineer" },
	{ label: "statistical assistant", value: "statistical assistant" },
	{ label: "statistician", value: "statistician" },
	{ label: "steamfitter", value: "steamfitter" },
	{ label: "stock clerk", value: "stock clerk" },
	{ label: "stock mover", value: "stock mover" },
	{ label: "stonemason", value: "stonemason" },
	{ label: "street vendor", value: "street vendor" },
	{ label: "streetcar operator", value: "streetcar operator" },
	{ label: "structural iron worker", value: "structural iron worker" },
	{
		label: "structural metal fabricator",
		value: "structural metal fabricator",
	},
	{ label: "structural metal fitter", value: "structural metal fitter" },
	{ label: "structural steel worker", value: "structural steel worker" },
	{ label: "stucco mason", value: "stucco mason" },
	{ label: "substance abuse counselor", value: "substance abuse counselor" },
	{
		label: "substance abuse social worker",
		value: "substance abuse social worker",
	},
	{ label: "subway operator", value: "subway operator" },
	{
		label: "surfacing equipment operator",
		value: "surfacing equipment operator",
	},
	{ label: "surgeon", value: "surgeon" },
	{ label: "surgical technologist", value: "surgical technologist" },
	{ label: "survey researcher", value: "survey researcher" },
	{ label: "surveying technician", value: "surveying technician" },
	{ label: "surveyor", value: "surveyor" },
	{ label: "switch operator", value: "switch operator" },
	{ label: "switchboard operator", value: "switchboard operator" },
	{ label: "tailor", value: "tailor" },
	{ label: "tamping equipment operator", value: "tamping equipment operator" },
	{ label: "tank car loader", value: "tank car loader" },
	{ label: "taper", value: "taper" },
	{ label: "tax collector", value: "tax collector" },
	{ label: "tax examiner", value: "tax examiner" },
	{ label: "tax preparer", value: "tax preparer" },
	{ label: "taxi driver", value: "taxi driver" },
	{ label: "teacher assistant", value: "teacher assistant" },
	{ label: "teacher", value: "teacher" },
	{ label: "team assembler", value: "team assembler" },
	{ label: "technical writer", value: "technical writer" },
	{
		label: "telecommunications equipment installer",
		value: "telecommunications equipment installer",
	},
	{ label: "telemarketer", value: "telemarketer" },
	{ label: "telephone operator", value: "telephone operator" },
	{ label: "television announcer", value: "television announcer" },
	{ label: "teller", value: "teller" },
	{ label: "terrazzo finisher", value: "terrazzo finisher" },
	{ label: "terrazzo worker", value: "terrazzo worker" },
	{ label: "tester", value: "tester" },
	{ label: "textile bleaching operator", value: "textile bleaching operator" },
	{
		label: "textile cutting machine setter",
		value: "textile cutting machine setter",
	},
	{
		label: "textile knitting machine setter",
		value: "textile knitting machine setter",
	},
	{ label: "textile presser", value: "textile presser" },
	{ label: "textile worker", value: "textile worker" },
	{ label: "therapist", value: "therapist" },
	{ label: "ticket agent", value: "ticket agent" },
	{ label: "ticket taker", value: "ticket taker" },
	{ label: "tile setter", value: "tile setter" },
	{ label: "timekeeping clerk", value: "timekeeping clerk" },
	{ label: "timing device assembler", value: "timing device assembler" },
	{ label: "tire builder", value: "tire builder" },
	{ label: "tire changer", value: "tire changer" },
	{ label: "tire repairer", value: "tire repairer" },
	{ label: "title abstractor", value: "title abstractor" },
	{ label: "title examiner", value: "title examiner" },
	{ label: "title searcher", value: "title searcher" },
	{
		label: "tobacco roasting machine operator",
		value: "tobacco roasting machine operator",
	},
	{ label: "tool filer", value: "tool filer" },
	{ label: "tool grinder", value: "tool grinder" },
	{ label: "tool maker", value: "tool maker" },
	{ label: "tool sharpener", value: "tool sharpener" },
	{ label: "tour guide", value: "tour guide" },
	{ label: "tower equipment installer", value: "tower equipment installer" },
	{ label: "tower operator", value: "tower operator" },
	{ label: "track switch repairer", value: "track switch repairer" },
	{ label: "tractor operator", value: "tractor operator" },
	{
		label: "tractor-trailer truck driver",
		value: "tractor-trailer truck driver",
	},
	{ label: "traffic clerk", value: "traffic clerk" },
	{ label: "traffic technician", value: "traffic technician" },
	{
		label: "training and development manager",
		value: "training and development manager",
	},
	{
		label: "training and development specialist",
		value: "training and development specialist",
	},
	{ label: "transit police", value: "transit police" },
	{ label: "translator", value: "translator" },
	{
		label: "transportation equipment painter",
		value: "transportation equipment painter",
	},
	{ label: "transportation inspector", value: "transportation inspector" },
	{
		label: "transportation security screener",
		value: "transportation security screener",
	},
	{ label: "transportation worker", value: "transportation worker" },
	{ label: "trapper", value: "trapper" },
	{ label: "travel agent", value: "travel agent" },
	{ label: "travel clerk", value: "travel clerk" },
	{ label: "travel guide", value: "travel guide" },
	{ label: "tree pruner", value: "tree pruner" },
	{ label: "tree trimmer", value: "tree trimmer" },
	{ label: "trimmer", value: "trimmer" },
	{ label: "truck loader", value: "truck loader" },
	{ label: "truck mechanic", value: "truck mechanic" },
	{ label: "tuner", value: "tuner" },
	{
		label: "turning machine tool operator",
		value: "turning machine tool operator",
	},
	{ label: "tutor", value: "tutor" },
	{ label: "typist", value: "typist" },
	{ label: "umpire", value: "umpire" },
	{ label: "undertaker", value: "undertaker" },
	{ label: "upholsterer", value: "upholsterer" },
	{ label: "urban planner", value: "urban planner" },
	{ label: "usher", value: "usher" },
	{ label: "UX designer", value: "UX designer" },
	{ label: "valve installer", value: "valve installer" },
	{ label: "vending machine servicer", value: "vending machine servicer" },
	{ label: "veterinarian", value: "veterinarian" },
	{ label: "veterinary assistant", value: "veterinary assistant" },
	{ label: "veterinary technician", value: "veterinary technician" },
	{ label: "vocational counselor", value: "vocational counselor" },
	{
		label: "vocational education teacher",
		value: "vocational education teacher",
	},
	{ label: "waiter", value: "waiter" },
	{ label: "waitress", value: "waitress" },
	{ label: "watch repairer", value: "watch repairer" },
	{
		label: "water treatment plant operator",
		value: "water treatment plant operator",
	},
	{ label: "weaving machine setter", value: "weaving machine setter" },
	{ label: "web developer", value: "web developer" },
	{ label: "weigher", value: "weigher" },
	{ label: "welder", value: "welder" },
	{ label: "wellhead pumper", value: "wellhead pumper" },
	{ label: "wholesale buyer", value: "wholesale buyer" },
	{ label: "wildlife biologist", value: "wildlife biologist" },
	{ label: "window trimmer", value: "window trimmer" },
	{ label: "wood patternmaker", value: "wood patternmaker" },
	{ label: "woodworker", value: "woodworker" },
	{ label: "word processor", value: "word processor" },
	{ label: "writer", value: "writer" },
	{ label: "yardmaster", value: "yardmaster" },
	{ label: "zoologist", value: "zoologist" },
	{ label: "others", value: "others" },
];

// uppercase label and value first letter of each word
const updatedOptionOccupation = optionOccupation.map((option) => {
	const label = option.label
		.toLowerCase()
		.split(" ")
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(" ");
	const value = option.value
		.toLowerCase()
		.split(" ")
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(" ");
	return { label, value };
});

updatedOptionOccupation.sort((a, b) => {
	if (a.label < b.label) {
		return -1;
	}
	if (a.label > b.label) {
		return 1;
	}
	return 0;
});

export default updatedOptionOccupation;
