import { Modal, Button, Typography } from "antd";

export default function ModalDataConsent(props) {
	const { toggleModalDataConsent, setToggleModalDataConsent } = props;

	return (
		<Modal
			width={800}
			title="DATA PRIVACY CONSENT"
			open={toggleModalDataConsent.open}
			onCancel={() => {
				setToggleModalDataConsent({
					open: false,
					data: null,
				});
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalDataConsent({
							open: false,
							data: null,
						});
					}}
				>
					CLOSE
				</Button>,
			]}
		>
			<>
				<Typography.Title
					level={4}
					style={{
						textAlign: "center",
						marginTop: "10px",
						justifyContent: "justify",
					}}
				>
					DATA PRIVACY CONSENT
				</Typography.Title>

				<p
					style={{
						fontSize: "14px",
						marginBottom: "0px",
						textAlign: "justify",
					}}
				>
					By accepting this Data Privacy Statement, I grant my free, voluntary,
					and unconditional consent to the collection and processing of all
					Personal data to the information system of Father Saturnino Urios
					University Guidance Center by whatever means in accordance with the
					Republic Act 10173, otherwise known as the “Data Privacy Act of 2012”
					of the Republic of the Philippines, including its Implementing Rules
					and Regulations (IRR) as well as other guidelines and issuances by the
					National Privacy Commission (NPC). I also consent to the following:
				</p>
				<br />
				<ol>
					<li>
						<p>
							The FSUU Guidance Center may collect personal data during my
							application for admission purposes.
						</p>
					</li>
					<li>
						<p>
							My personal information can be accessed and used only by
							authorized personnel and officials connected to the University for
							legitimate purposes only.
						</p>
					</li>
					<li>
						<p>
							The FSUU Guidance Center may share or disclose some of my personal
							information to others in its process to deliver necessary services
							for the stakeholders and the institution, including but not
							limited to:
						</p>

						<ol>
							<li>
								a. Sharing information for accreditation purposes (PAASCU, CHED)
							</li>
							<li>
								b. Conducting research or surveys for purposes of institutional
								development
							</li>
							<li>
								c. All guidance and counseling-related functions and services
							</li>
							<li>d. Other legitimate processes of the University</li>
						</ol>
					</li>
				</ol>
			</>
		</Modal>
	);
}
