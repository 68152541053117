import { useState } from "react";
import {
	Button,
	Col,
	Form,
	Row,
	Popconfirm,
	Space,
	Divider,
	Input,
	Tooltip,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import FloatInput from "../../../../providers/FloatInput";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatSelect from "../../../../providers/FloatSelect";

import validateRules from "../../../../providers/validateRules";
import optionOccupation from "../../../../providers/optionOccupation";

export default function ApplicantFormParentInfo(props) {
	const { formDisabled, form } = props;

	const RenderInput = (props) => {
		const { formDisabled, name, restField, fields, remove } = props;

		// Add Occupation
		const [currentOccupation, setCurrentOccupation] = useState(
			optionOccupation
		);

		const [newOccupation, setNewOccupation] = useState("");

		const handleAddOccupation = () => {
			if (newOccupation) {
				setCurrentOccupation((prevOptions) => [
					...prevOptions,
					{ label: newOccupation, value: newOccupation },
				]);
				setNewOccupation("");
			}
		};

		// // Birthdate
		// const [birthdate, setBirthdate] = useState(null);
		// const [age, setAge] = useState(null);

		// const onBirthdateChange = (date, dateString) => {
		// 	const dayjsDate = dayjs(date);
		// 	const dayjsDateString = dayjs(dateString);
		// 	setBirthdate(dayjsDateString);
		// 	calculateAge(dayjsDate);
		// };

		// // Age base on birthdate
		// const calculateAge = (date) => {
		// 	const today = dayjs();
		// 	const birthDate = dayjs(date);
		// 	let calculatedAge = today.diff(birthDate, "years");

		// 	if (calculatedAge < 3 || calculatedAge > 110) {
		// 		calculatedAge = null;
		// 	}

		// 	setAge(calculatedAge);
		// };

		// console.log("AGEsss: ", age);

		// useEffect(() => {
		// 	form.setFieldsValue({ [`${name}.age`]: age });
		// }, [age, form, name]);

		return (
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "firstname"]}
						rules={[
							validateRules.quillValidator,
							validateRules.alphabet_symbol,
							validateRules.trim,
						]}
					>
						<FloatInput
							label="First Name"
							placeholder="First Name"
							required={true}
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "middlename"]}
						rules={[validateRules.alphabet, validateRules.trim]}
					>
						<FloatInput
							label="Middle Name"
							placeholder="Middle Name"
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "lastname"]}
						rules={[
							validateRules.quillValidator,
							validateRules.alphabet,
							validateRules.trim,
						]}
					>
						<FloatInput
							label="Last Name"
							placeholder="Last Name"
							required={true}
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={4} xl={4}>
					<Form.Item
						{...restField}
						name={[name, "name_ext"]}
						rules={[validateRules.alphabet_symbol, validateRules.name_suffix]}
					>
						<FloatInput
							label="Extension Name"
							placeholder="Extension Name"
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={2} xl={2}>
					<div className="action">
						{fields.length > 1 ? (
							<Popconfirm
								title="Are you sure to delete this?"
								onConfirm={() => {
									remove(name);
								}}
								onCancel={() => {}}
								okText="Yes"
								cancelText="No"
								placement="topRight"
								okButtonProps={{
									className: "btn-main-invert",
								}}
							>
								<Button type="link" className="form-list-remove-button p-0">
									<FontAwesomeIcon icon={faTrashAlt} className="fa-lg" />
								</Button>
							</Popconfirm>
						) : null}
					</div>
				</Col>

				{/* <Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "birthdate"]}
						rules={[
							validateRules.quillValidator,
							{
								validator: (_, value) => {
									const today = dayjs();
									const birthdate = dayjs(value);
									const age = today.year() - birthdate.year();

									if (age < 18 && age < 110) {
										return Promise.reject(new Error("Age must be at least 18"));
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<FloatDatePicker
							className="birthdate"
							label="Date of Birth"
							placeholder="Date of Birth"
							required={true}
							allowClear
							onChange={(date, dateString) => {
								onBirthdateChange(dayjs(date), dayjs(dateString));
								setBirthdate(dayjs(dateString));
							}}
							format={{
								format: "MM-DD-YYYY",
								type: "mask",
							}}
						/>
					</Form.Item>
				</Col> */}

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Tooltip title="Please select birthdate">
						<Form.Item
							{...restField}
							name={[name, "age"]}
							rules={[
								validateRules.number,
								{
									validator: (_, value) => {
										if (value < 16 || value > 110) {
											return Promise.reject(
												new Error(
													"Age must be at least 16 years old and below 110"
												)
											);
										}
										return Promise.resolve();
									},
								},
							]}
						>
							<FloatInput label="Age" placeholder="Age" required={true} />
						</Form.Item>
					</Tooltip>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item {...restField} name={[name, "occupation"]}>
						<FloatSelect
							label="Occupation"
							placeholder="Occupation"
							allowClear={true}
							disabled={formDisabled}
							options={currentOccupation}
							dropdownRender={(menu) => (
								<>
									{menu}
									<Divider
										style={{
											margin: "8px 0",
										}}
									/>
									<Space
										style={{
											padding: "0 8px 4px",
										}}
									>
										<Input
											style={{
												border: "1px solid",
											}}
											value={newOccupation}
											onChange={(e) => setNewOccupation(e.target.value)}
										/>
										<Button
											className="btn-main-primary"
											type="text"
											icon={<FontAwesomeIcon icon={faPlus} />}
											name="btn-add-occupation"
											onClick={handleAddOccupation}
										>
											Add Occupation
										</Button>
									</Space>
								</>
							)}
						/>
					</Form.Item>
				</Col>

				<Col
					xs={24}
					sm={12}
					md={12}
					lg={6}
					xl={6}
					rules={[validateRules.quillValidator]}
				>
					<Form.Item
						{...restField}
						name={[name, "relation"]}
						rules={[validateRules.quillValidator]}
					>
						<FloatSelect
							label="Relationship"
							placeholder="Relationship"
							required={true}
							disabled={formDisabled}
							options={[
								{ label: "Mother", value: "Mother" },
								{ label: "Father", value: "Father" },
							]}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "contact_number"]}
						rules={[validateRules.phone, validateRules.quillValidator]}
					>
						<FloatInputMask
							label="Contact Number"
							placeholder="Contact Number"
							maskLabel="contact_number"
							maskType="(+63) 999 999 9999"
							required={true}
						/>
					</Form.Item>
				</Col>
			</Row>
		);
	};

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Form.List name="parent_list">
					{(fields, { add, remove }) => (
						<Row gutter={[12, 0]}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								{fields.map(({ key, name, ...restField }, index) => (
									<div key={key} className={`${index !== 0 ? "mt-25" : ""}`}>
										<RenderInput
											formDisabled={formDisabled}
											name={name}
											restField={restField}
											fields={fields}
											remove={remove}
										/>
									</div>
								))}
							</Col>

							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Button
									type="link"
									className="btn-main-primary p-0"
									icon={<FontAwesomeIcon icon={faPlus} />}
									onClick={() => add()}
								>
									Add Another Parent
								</Button>
							</Col>
						</Row>
					)}
				</Form.List>
				<hr className="form-family-group" />
			</Col>
		</Row>
	);
}
