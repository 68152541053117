import { useEffect } from "react";
import { Modal, Button, Form, notification } from "antd";
import FloatInputPassword from "../../../../providers/FloatInputPassword";
import validateRules from "../../../../providers/validateRules";
import { POST } from "../../../../providers/useAxiosQuery";
import notificationErrors from "../../../../providers/notificationErrors";

export default function ModalAuthPassword(props) {
	const { toggleModalAuthPassword, setToggleModalAuthPassword } = props;

	const [form] = Form.useForm();
	const { mutate: mutateExamResult, isLoading: isLoadingExamResult } = POST(
		`api/upload_student_exam_result`,
		"student_exams_list"
	);

	const {
		mutate: mutateApprovalStatus,
		isLoading: isLoadingApprovalStatus,
	} = POST(`api/multiple_applicant_approval_status`, "student_exams_list");

	const { mutate: mutateExamStatus, isLoading: isLoadingExamStatus } = POST(
		`api/multiple_applicant_exam_status`,
		"student_exams_list"
	);

	let onFinish = () => {};

	if (
		toggleModalAuthPassword.tableFilter &&
		toggleModalAuthPassword.tableFilter.tabActive ===
			"Approval Schedule List View"
	) {
		onFinish = (values) => {
			let newValues =
				toggleModalAuthPassword.copyDataSource &&
				toggleModalAuthPassword.copyDataSource.map((data) => ({
					id: data.id,
					or_number: data.or_number,
					schedule_status: data.or_number ? "Approved" : data.schedule_status,
					personal_email: data.personal_email,
					firstname: data.profile.firstname,
					middlename: data.profile.middlename ? data.profile.middlename : "",
					lastname: data.profile.lastname ? data.profile.lastname : "",
					link_origin: window.location.origin,
					exam_category_id: data.exam_category_id,
					exam_fee: data.exam_fee,
				}));

			let data = {
				...values,
				newValues: newValues,
				exam_schedule_id:
					toggleModalAuthPassword.toggleModalFormExamORNumber &&
					toggleModalAuthPassword.toggleModalFormExamORNumber.exam_schedule_id,
			};

			mutateApprovalStatus(data, {
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: "Exam Status",
							description: res.message,
						});
						form.resetFields();
						toggleModalAuthPassword.setCopyDataSource([]);
						setToggleModalAuthPassword({
							open: false,
							data: null,
						});
					} else {
						notification.error({
							message: "Exam Approval Status",
							description: res.message,
						});
					}
				},
				onError: (err) => {
					notificationErrors(err);
				},
			});
		};
	} else if (
		toggleModalAuthPassword.tableFilter &&
		toggleModalAuthPassword.tableFilter.tabActive === "Exam Status List View"
	) {
		onFinish = (values) => {
			let newValues =
				toggleModalAuthPassword.copyDataSource &&
				toggleModalAuthPassword.copyDataSource.map((data) => ({
					id: data.id,
					personal_email: data.personal_email,
					exam_sheet_number: data.exam_sheet_number,
					firstname: data.firstname,
					middlename: data.middlename ? data.middlename : "",
					lastname: data.lastname ? data.lastname : "",
					exam_category_id: data.exam_category_id,
					or_number: data.or_number,
					exam_schedule_id: data.exam_schedule_id,
					exam_fee: data.exam_fee,
					link_origin: window.location.origin,
				}));
			let data = {
				...values,
				newValues: newValues,
				exam_schedule_id:
					toggleModalAuthPassword.toggleModalFormExamSheetNumber &&
					toggleModalAuthPassword.toggleModalFormExamSheetNumber
						.exam_schedule_id,
			};
			mutateExamStatus(data, {
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: "Exam Status",
							description: res.message,
						});
						setToggleModalAuthPassword({
							open: false,
							data: null,
						});
						form.resetFields();
					} else {
						notification.error({
							message: "Exam Status",
							description: res.message,
						});
					}
				},
				onError: (err) => {
					notificationErrors(err);
				},
			});
		};
	} else if (
		toggleModalAuthPassword.tableFilter &&
		toggleModalAuthPassword.tableFilter.tabActive === "Exam Result List View"
	) {
		onFinish = (values) => {
			let data = new FormData();

			// console.log("values ModalAuthPassword: ", values);

			data.append(
				"exam_category_id",
				parseInt(
					toggleModalAuthPassword.data &&
						toggleModalAuthPassword.data.exam_category_id,
					10
				)
			);

			if (
				toggleModalAuthPassword.data &&
				toggleModalAuthPassword.data.file_excel &&
				toggleModalAuthPassword.data &&
				toggleModalAuthPassword.data.file_excel[0] &&
				toggleModalAuthPassword.data &&
				toggleModalAuthPassword.data.file_excel[0].originFileObj
			) {
				data.append(
					"file_excel",
					toggleModalAuthPassword.data &&
						toggleModalAuthPassword.data.file_excel[0].originFileObj,
					toggleModalAuthPassword.data &&
						toggleModalAuthPassword.data.file_excel[0].originFileObj.name
				);
			}

			data.append("link_origin", window.location.origin);
			data.append("auth_password", values.auth_password);

			mutateExamResult(data, {
				onSuccess: (res) => {
					notification.success({
						message: "Exam results uploaded successfully.",
						description: res.message,
					});
					setToggleModalAuthPassword({
						open: false,
						data: null,
					});
					form.resetFields();
				},
				onError: (err) => {
					notificationErrors(err);
				},
			});
		};
	}

	useEffect(() => {
		if (toggleModalAuthPassword.open) {
			let data = { ...toggleModalAuthPassword.data };

			form.setFieldsValue(data);

			// console.log("data ModalAuthPassword: ", data);
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalAuthPassword]);

	return (
		<Modal
			title="Confirm Changes"
			open={toggleModalAuthPassword.open}
			onCancel={() => {
				setToggleModalAuthPassword({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalAuthPassword({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					htmlType="submit"
					type="primary"
					key={2}
					loading={
						toggleModalAuthPassword.tableFilter &&
						toggleModalAuthPassword.tableFilter.tabActive ===
							"Approval Schedule List View"
							? isLoadingApprovalStatus
							: toggleModalAuthPassword.tableFilter &&
							  toggleModalAuthPassword.tableFilter.tabActive ===
									"Exam Result List View"
							? isLoadingExamResult
							: null
					}
					onClick={() => {
						form.submit();
					}}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="auth_password" rules={[validateRules.quillValidator]}>
					<FloatInputPassword
						label="Password"
						placeholder="Password"
						required={true}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
