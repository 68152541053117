import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import {
	faHome,
	faUsers,
	faGraduationCap,
	faEnvelopes,
	faCogs,
} from "@fortawesome/pro-regular-svg-icons";

import dayjs from "dayjs";
import { GET } from "../providers/useAxiosQuery";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import PageRequestPermission from "../views/errors/PageRequestPermission";
import Page404 from "../views/errors/Page404";

import PageLogin from "../views/public/PageLogin/PageLogin";
import PageRegister from "../views/public/PageRegister/PageRegister";

import PageEditProfile from "../views/private/PageEditProfile/PageEditProfile";
import PageDashboard from "../views/private/PageDashboard/PageDashboard";

import PageApplicant from "../views/private/PageApplicants/PageApplicant";
import PageApplicantFormAdd from "../views/private/PageApplicants/PageApplicantFormAdd";
import PageApplicantFormEdit from "../views/private/PageApplicants/PageApplicantFormEdit";

import PageEntranceExam from "../views/private/PageEntranceExam/PageEntranceExam";
import PageApplicantList from "../views/private/PageApplicants/PageApplicantList";
import PageReport from "../views/private/PageReport/PageReport";
import PageSettings from "../views/private/PageReferences/PageSettings/PageSettings";
import PageEmailTemplate from "../views/private/PageEmailTemplate/PageEmailTemplate";
import PageGatePass from "../views/public/PageGatePass/PageGatePass";
import PageTest from "../views/private/PageTest";

export default function RouteList() {
	const location = useLocation();

	// From TableExamSchedule
	const newPath = location.pathname.split("/")[3];

	const { data: dataExamSchedule } = GET(
		`api/ref_exam_schedule`,
		"exam_schedule_list",
		(res) => {},
		false
	);

	// console.log("dataExamSchedule: ", dataExamSchedule);

	let examDate = "";
	let examStart = "";
	let examEnd = "";

	if (
		dataExamSchedule &&
		dataExamSchedule.data &&
		dataExamSchedule.data.length > 0
	) {
		const matchingItem = dataExamSchedule.data.find(
			(item) => item.id === Number(newPath)
		);

		if (matchingItem) {
			examDate = matchingItem.exam_date;
			examStart = matchingItem.time_in + " " + matchingItem.time_in_meridiem;
			examEnd = matchingItem.time_out + " " + matchingItem.time_out_meridiem;
		}
	}

	let formattedDate = dayjs(examDate).format("MMMM DD, YYYY");

	// console.log("dataExamSchedule RouteList:", dataExamSchedule);
	// console.log("newPath :", newPath);
	// console.log("examDate :", examDate);
	// console.log("examStart :", examStart);
	// console.log("formattedDate :", formattedDate);

	return (
		<Routes>
			<Route
				path="/"
				element={
					<PublicRoute title="LOGIN" pageId="PageLogin" component={PageLogin} />
				}
			/>

			<Route
				path="/registration"
				element={
					<PublicRoute
						title="REGISTRATION"
						pageId="PageRegistration"
						component={PageRegister}
					/>
				}
			/>

			<Route
				path="/gate-pass-display"
				element={
					<PublicRoute
						title="REGISTRATION"
						pageId="PageGatePassDisplay"
						component={PageGatePass}
					/>
				}
			/>

			<Route
				path="/edit-profile"
				element={
					<PrivateRoute
						moduleName="Edit Profile"
						title="User"
						subtitle="VIEW / EDIT"
						pageId="PageUserProfile"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Edit Profile",
							},
						]}
						component={PageEditProfile}
					/>
				}
			/>

			<Route
				path="/dashboard"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Dashboard"
						title="Dashboard"
						subtitle="ADMIN"
						pageId="PageDashboard"
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Dashboard",
							},
						]}
						component={PageDashboard}
					/>
				}
			/>

			{/* START WALK-IN APPLICANT */}
			<Route
				path="/applicants/walk-in"
				element={
					<PrivateRoute
						moduleCode="M-03"
						moduleName="Applicant Walk-in Schedule"
						title="Applicant"
						subtitle="WALK-IN"
						pageId="PageApplicant"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant ",
								link: "/applicants/walk-in",
							},
							{
								name: "Walk-In",
							},
						]}
						component={PageApplicant}
					/>
				}
			/>

			<Route
				path="/applicants/walk-in/:id"
				element={
					<PrivateRoute
						moduleCode="M-04"
						moduleName="Applicant Walk-in View List"
						title="Applicant"
						subtitle="WALK-IN"
						pageId="PageApplicant"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant ",
								link: "/applicants/walk-in",
							},
							{
								name: "Walk-In",
								link: "/applicants/walk-in",
							},
							{
								name:
									formattedDate +
									" " +
									"( " +
									examStart +
									" - " +
									examEnd +
									" )",
							},
						]}
						component={PageApplicantList}
					/>
				}
			/>

			<Route
				path="/applicants/walk-in/:id/add"
				element={
					<PrivateRoute
						moduleCode="M-05"
						moduleName="Applicant Walk-In Add"
						title="Applicant"
						subtitle="WALK-IN ADD"
						pageId="PageApplicantFormAdd"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
								link: "/applicants/walk-in",
							},
							{
								name: "Walk-In",
								link: "/applicants/walk-in",
							},
							{
								name:
									formattedDate +
									" " +
									"( " +
									examStart +
									" - " +
									examEnd +
									" )",
							},
						]}
						component={PageApplicantFormAdd}
					/>
				}
			/>

			<Route
				path="/applicants/walk-in/add"
				element={
					<PrivateRoute
						moduleCode="M-05"
						moduleName="Applicant Walk-In Add"
						title="Applicant"
						subtitle="WALK-IN ADD"
						pageId="PageApplicantFormAdd"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
								link: "/applicants/walk-in",
							},
							{
								name: "Walk-In",
								link: "/applicants/walk-in",
							},
							{
								name: "Add",
							},
						]}
						component={PageApplicantFormAdd}
					/>
				}
			/>

			<Route
				path="/applicants/walk-in/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-06"
						moduleName="Applicant Walk-In Edit"
						title="Applicant"
						subtitle="WALK-IN EDIT"
						pageId="PageApplicantFormEdit"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
								link: "/applicants/walk-in",
							},
							{
								name: "Walk-In",
								link: "/applicants/walk-in",
							},
							{
								name: "Edit",
							},
						]}
						component={PageApplicantFormEdit}
					/>
				}
			/>
			{/* END WALK-IN APPLICANT */}

			{/* START ONLINE APPLICANT */}
			<Route
				path="/applicants/online"
				element={
					<PrivateRoute
						moduleCode="M-07"
						moduleName="Applicant Online Schedule"
						title="Applicant"
						subtitle="ONLINE"
						pageId="PageApplicant"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant ",
								link: "/applicants/online",
							},
							{
								name: "Online",
							},
						]}
						component={PageApplicant}
					/>
				}
			/>

			<Route
				path="/applicants/online/:id"
				element={
					<PrivateRoute
						moduleCode="M-08"
						moduleName="Applicant Online View List"
						title="Applicant"
						subtitle="ONLINE"
						pageId="PageApplicant"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant ",
								link: "/applicants/online",
							},
							{
								name: "Online",
								link: "/applicants/online",
							},
						]}
						component={PageApplicantList}
					/>
				}
			/>

			<Route
				path="/applicants/online/add"
				element={
					<PrivateRoute
						moduleCode="M-09"
						moduleName="Applicant Online Add"
						title="Applicant"
						subtitle="ONLINE ADD"
						pageId="PageApplicantFormAdd"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
								link: "/applicants/online",
							},
							{
								name: "Online",
								link: "/applicants/online",
							},
							{
								name: "Add",
							},
						]}
						component={PageApplicantFormAdd}
					/>
				}
			/>

			<Route
				path="/applicants/online/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-10"
						moduleName="Applicant Online Edit"
						title="Applicant"
						subtitle="ONLINE EDIT"
						pageId="PageApplicantFormEdit"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
								link: "/applicants/online",
							},
							{
								name: "Online",
								link: "/applicants/online",
							},
							{
								name: "Edit",
							},
						]}
						component={PageApplicantFormEdit}
					/>
				}
			/>
			{/* END ONLINE APPLICANT */}

			{/* START ARCHIVED */}
			<Route
				path="/applicants/archived"
				element={
					<PrivateRoute
						moduleCode="M-11"
						moduleName="Applicant Archived View"
						title="Applicant"
						subtitle="ARCHIVED"
						pageId="PageApplicantFormAdd"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
							},
							{
								name: "Archived",
							},
						]}
						component={PageApplicant}
					/>
				}
			/>
			<Route
				path="/applicants/archived/:id"
				element={
					<PrivateRoute
						moduleCode="M-12"
						moduleName="Applicant Archived List"
						title="Applicant"
						subtitle="ARCHIVED"
						pageId="PageApplicant"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
							},
							{
								name: "Archived",
							},
						]}
						component={PageApplicantList}
					/>
				}
			/>
			{/* END ARCHIVED */}

			{/* START ENTRANCE EXAM */}
			<Route
				path="/entrance-exam/schedule"
				element={
					<PrivateRoute
						moduleCode="M-02"
						moduleName="Entrance Exam"
						title="Entrance Exam"
						subtitle="SCHEDULE"
						pageId="PageEntranceExamScheduling"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Entrance Exam",
							},
							{
								name: "Schedule",
							},
						]}
						component={PageEntranceExam}
					/>
				}
			/>

			{/* REPORTS */}
			<Route
				path="/reports"
				element={
					<PrivateRoute
						moduleCode="M-13"
						moduleName="Reports"
						title="Reports"
						subtitle="ENTRANCE EXAM"
						pageId="PageReport"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},

							{
								name: "Reports",
							},
						]}
						component={PageReport}
					/>
				}
			/>

			{/* SETTINGS */}
			<Route
				path="/settings"
				element={
					<PrivateRoute
						moduleCode="M-11"
						moduleName="Admin Settings"
						title="List"
						subtitle="SETTINGS"
						pageId="PageAdminSettings"
						pageHeaderIcon={faCogs}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Settings",
							},
						]}
						component={PageSettings}
					/>
				}
			/>

			{/* EMAIL TEMPLATE */}
			<Route
				path="/email-template"
				element={
					<PrivateRoute
						moduleCode="M-12"
						moduleName="Email Template"
						title="Template"
						subtitle="EMAIL"
						pageId="PageEmailTemplate"
						pageClassName="email-template"
						pageHeaderIcon={faEnvelopes}
						breadcrumb={[
							{
								name: "Email Template",
							},
						]}
						component={PageEmailTemplate}
					/>
				}
			/>

			{/* VOTING */}
			<Route
				path="/test"
				element={
					<PageTest
						moduleCode="M-12"
						moduleName="Email Template"
						title="Template"
						subtitle="EMAIL"
						pageId="PageEmailTemplate"
						pageClassName="email-template"
						pageHeaderIcon={faEnvelopes}
						breadcrumb={[
							{
								name: "Email Template",
							},
						]}
					/>
				}
			/>

			<Route path="/request-permission" element={<PageRequestPermission />} />
			<Route path="*" element={<Page404 />} />
		</Routes>
	);
}
