import { useState } from "react";
import {
	Row,
	Col,
	Table,
	Button,
	Checkbox,
	Divider,
	Dropdown,
	Space,
} from "antd";

import {
	TableDropdownFilter,
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import ModalUploadExamResult from "./ModalUploadExamResult";
import ModalExamResultPreview from "./ModalExamResultPreview";

export default function TableExamResult(props) {
	const {
		dataSource,
		dataExamCategory,
		tableFilter,
		setTableFilter,
		location,
	} = props;

	// console.log("tableFilter: ", tableFilter);

	const [
		toggleModalUploadExamResult,
		setToggleModalUploadExamResult,
	] = useState({ open: false, data: null });

	const examResult = dataSource?.data?.data?.[0]?.exam_result;

	const [
		toggleModalExamResultPreview,
		setToggleModalExamResultPreview,
	] = useState({
		open: false,
		data: null,
	});

	useTableScrollOnTop("tblExamResult", location.pathname);

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper_examresult">
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-top-filter">
						<div style={{ display: "flex", gap: 8 }}>
							<Button
								className="btn-main-primary mb-10"
								onClick={() => {
									setToggleModalUploadExamResult({
										open: true,
										data: null,
									});
								}}
							>
								Upload Exam Result
							</Button>

							<TableDropdownFilter
								items={
									dataExamCategory &&
									dataExamCategory.data && [
										...dataExamCategory.data.map((item) => ({
											key: item.id.toString(),
											label: (
												<>
													<Checkbox
														id={`chck_${item.category}`}
														checked={
															tableFilter &&
															tableFilter.category &&
															tableFilter.category.filter(
																(f) => f === item.category
															).length > 0
														}
														onChange={(e) => {
															let category =
																tableFilter && tableFilter.category
																	? tableFilter.category
																	: [];
															let categoryFilter = tableFilter.category.filter(
																(f) => f === item.category
															);
															if (e.target.checked) {
																if (categoryFilter.length === 0) {
																	category.push(item.category);
																}
															} else {
																category = category.filter(
																	(f) => f !== item.category
																);
															}

															setTableFilter((ps) => ({
																...ps,
																category,
															}));
														}}
													/>

													<label htmlFor={`chck_${item.category}`}>
														{item.category}
													</label>
												</>
											),
										})),
										{
											key: "divider",
											label: <Divider style={{ margin: "0px" }} />,
										},
										{
											key: "other-filter",
											label: (
												<>
													<Checkbox
														id="chck_exam_result"
														checked={tableFilter && tableFilter.exam_result}
														onChange={(e) => {
															setTableFilter((ps) => ({
																...ps,
																exam_result: e.target.checked,
															}));
														}}
													/>
												</>
											),
										},
									]
								}
							/>

							<TableGlobalSearchAnimated
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
						<div style={{ display: "flex", gap: 12 }}>
							<TableShowingEntriesV2 />
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<Table
						id="tblExamResult"
						className="ant-table-default ant-table-striped"
						dataSource={dataSource && dataSource.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						scroll={{ x: "max-content" }}
						sticky
					>
						<Table.Column
							title="Preview"
							key="preview_attachment"
							align="center"
							width={100}
							render={(_, record) => {
								// console.log("record test: ", record);

								return (
									<Button
										type="link"
										className="color-1"
										onClick={() => {
											setToggleModalExamResultPreview({
												open: true,
												data: record,
											});
										}}
										disabled={examResult != null ? false : true}
									>
										<FontAwesomeIcon icon={faEye} />
									</Button>
								);
							}}
						/>

						<Table.Column
							title="OR Number"
							key="or_number"
							dataIndex="or_number"
							sorter={(a, b) => a.or_number.localeCompare(b.or_number)}
							width={150}
						/>

						<Table.Column
							title="Answer Sheet No."
							key="exam_sheet_number"
							dataIndex="exam_sheet_number"
							width={150}
							render={(text, record) => <>{record.answer_sheet_no ?? "N/A"}</>}
							sorter={(a, b) => a.answer_sheet_no - b.answer_sheet_no}
						/>

						<Table.Column
							title="Exam Category"
							key="exam_category_id"
							dataIndex="exam_category_id"
							sorter={(a, b) => a.exam_category_id - b.exam_category_id}
							render={(text, record) => {
								return <>{record.exam_category}</>;
							}}
							width={150}
						/>

						<Table.Column
							title="Student Status"
							key="student_status"
							dataIndex="student_status"
							sorter={(a, b) =>
								a.student_status.localeCompare(b.student_status)
							}
							width={150}
						/>

						<Table.Column
							title="Student Name"
							key="fullname"
							dataIndex="fullname"
							sorter={(a, b) => a.fullname.localeCompare(b.fullname)}
							render={(text, record) => {
								const fullname = record.fullname;
								return <>{` ${fullname} `}</>;
							}}
							width={150}
						/>

						<Table.Column
							title="Student Year Level"
							key="student_level"
							dataIndex="student_level"
							sorter={(a, b) => a.student_level.localeCompare(b.student_level)}
							width={150}
						/>

						<Table.Column
							title="Personal Email"
							key="personal_email"
							dataIndex="personal_email"
							sorter={(a, b) =>
								a.personal_email.localeCompare(b.personal_email)
							}
							width={150}
						/>
					</Table>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							setPaginationTotal={dataSource?.data?.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper_approval"
						/>
					</div>
				</Col>
			</Row>

			<ModalUploadExamResult
				toggleModalUploadExamResult={toggleModalUploadExamResult}
				setToggleModalUploadExamResult={setToggleModalUploadExamResult}
				dataSource={dataSource}
				tableFilter={tableFilter}
				setTableFilter={setTableFilter}
			/>

			<ModalExamResultPreview
				toggleModalExamResultPreview={toggleModalExamResultPreview}
				setToggleModalExamResultPreview={setToggleModalExamResultPreview}
			/>
		</>
	);
}
