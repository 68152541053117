import { useEffect, useState } from "react";
import { Button, Empty, Modal } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { GET } from "../../../../providers/useAxiosQuery";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function ModalExamResultPreview(props) {
	const {
		toggleModalExamResultPreview,
		setToggleModalExamResultPreview,
	} = props;

	const [numPages, setNumPages] = useState();
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = (e) => {
		setNumPages(e.numPages);
	};

	const [pdfAttachments, setPdfAttachments] = useState(null);

	const { data: dataAttachments } = GET(
		`api/exam_results`,
		"exam_results_list"
	);

	useEffect(() => {
		if (toggleModalExamResultPreview.data) {
			if (toggleModalExamResultPreview.data.pdf) {
				setPdfAttachments(toggleModalExamResultPreview.data.pdf);
			}
		}
		// convert to pdf

		return () => {};
	}, [toggleModalExamResultPreview, dataAttachments]);

	return (
		<Modal
			title={` Exam Result Preview | ${toggleModalExamResultPreview &&
				toggleModalExamResultPreview.data &&
				toggleModalExamResultPreview.data.fullname.toUpperCase()}`}
			wrapClassName="modal-wrap-upload-file-preview"
			open={toggleModalExamResultPreview.open}
			onCancel={() => {
				setToggleModalExamResultPreview({
					open: false,
					data: null,
				});
			}}
			footer={null}
		>
			{pdfAttachments ? (
				<>
					<Document file={pdfAttachments} onLoadSuccess={onDocumentLoadSuccess}>
						<Page pageNumber={pageNumber} renderTextLayer={false} />
					</Document>

					<div className="action">
						<Button
							onClick={() => setPageNumber((ps) => (ps > 1 ? ps - 1 : 1))}
							className={pageNumber > 1 ? "btn-main-primary" : ""}
						>
							PREV
						</Button>
						<Button
							onClick={() =>
								setPageNumber((ps) => (ps < numPages ? ps + 1 : numPages))
							}
							className={numPages !== pageNumber ? "btn-main-primary" : ""}
						>
							NEXT
						</Button>
					</div>
				</>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No File Pdf" />
			)}
		</Modal>
	);
}
