import { Row, Col, Collapse, Card, Flex } from "antd";
import { useQueryClient } from "react-query";

import Highcharts from "highcharts";
import highchartsSetOptions from "../../../providers/highchartsSetOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
require("highcharts/modules/accessibility")(Highcharts);
// require("highcharts/modules/no-data-to-display")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

export default function PageDashboard() {
	const queryClient = useQueryClient();
	highchartsSetOptions(Highcharts);

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
				<Flex>
					<Card
						hoverable
						style={{
							width: 300,
						}}
						href="#"
						className="dashboard-card"
					>
						<div className="top-corner">
							<div className="nav-arrow">
								<FontAwesomeIcon icon={faArrowRight} />
							</div>
						</div>
					</Card>

					<Card
						hoverable
						style={{
							width: 300,
						}}
						href="#"
						className="dashboard-card"
					>
						<div className="top-corner">
							<div className="nav-arrow">
								<FontAwesomeIcon icon={faArrowRight} />
							</div>
						</div>
					</Card>

					<Card
						hoverable
						style={{
							width: 300,
						}}
						href="#"
						className="dashboard-card"
					>
						<div className="top-corner">
							<div className="nav-arrow">
								<FontAwesomeIcon icon={faArrowRight} />
							</div>
						</div>
					</Card>
				</Flex>
			</Col>
		</Row>
	);
}
