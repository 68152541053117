import { useLocation, useNavigate } from "react-router-dom";
import {
	Row,
	Col,
	Table,
	Button,
	notification,
	Popconfirm,
	Tooltip,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash, faPlus } from "@fortawesome/pro-regular-svg-icons";

import {
	TableGlobalSearch,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";
import { DELETE, GET } from "../../../../providers/useAxiosQuery";
import notificationErrors from "../../../../providers/notificationErrors";

export default function TableApplicant(props) {
	const { dataSource, tableFilter, setTableFilter } = props;

	const navigate = useNavigate();
	const location = useLocation();

	// console.log("dataSource TAplicant: ", dataSource);

	let examDates2 = dataSource?.data?.data?.map((item) => item.exam_date);

	const { data: dataUserProfileInfo } = GET(
		`api/profile`,
		"profile",
		(res) => {},
		false
	);

	const {
		mutate: mutateDeactivateStudentSchedule,
		isLoading: isLoadingStudentExam,
	} = DELETE(`api/student_exams`, "student_exams_list");

	const handleDeactivate = (record) => {
		mutateDeactivateStudentSchedule(record, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Student",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Student",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useTableScrollOnTop("tblApplicant", location.pathname);

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper_applicant">
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-top-filter">
						<div style={{ display: "flex", gap: "15px" }}>
							<Col xs={24} sm={24} md={24} className="w-0 auto h-0 p-0">
								{location.pathname.includes("walk-in") ? (
									<Button
										className=" btn-main-primary btn-main-invert-outline b-r-none mr-10"
										icon={<FontAwesomeIcon icon={faPlus} />}
										onClick={() => {
											navigate(`${location.pathname}/add`, {
												state: {
													examScheduletoDate: examDates2,
													data: dataSource,
												},
											});
										}}
										name="btn_add"
										// exam_schedule_id={tableFilter.exam_schedule_id}
									>
										Add Walk-In Applicant
									</Button>
								) : null}

								<Button
									className={`btn-main-primary min-w-150 mr-10 ${
										tableFilter && tableFilter.status === "Active"
											? "active"
											: "outlined"
									}`}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											status: "Active",
										}));
									}}
								>
									Active
								</Button>
								<Button
									className={`btn-main-primary min-w-150 ${
										tableFilter && tableFilter.status === "Deactivated"
											? "active"
											: "outlined"
									}`}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											status: "Deactivated",
										}));
									}}
								>
									Archived
								</Button>
							</Col>
						</div>
						<div style={{ display: "flex", gap: 12 }}>
							<TableShowingEntriesV2 />
							<TableGlobalSearch
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<Table
						id="tblApplicant"
						className="ant-table-default ant-table-striped"
						dataSource={dataSource && dataSource.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						scroll={{ x: "max-content" }}
						sticky={{
							offsetHeader: 0,
						}}
					>
						{location.pathname.includes("archived") ? null : (
							<Table.Column
								title="Action"
								key="action"
								dataIndex="action"
								align="center"
								width={100}
								render={(text, record) => {
									// console.log("record:", record);
									return (
										<>
											<Tooltip title={"Edit Information"}>
												<Button
													type="link"
													className="primary-color w-auto h-auto p-0"
													onClick={() => {
														navigate(
															`/applicants/walk-in/edit/${record.profile_id}`
														);
													}}
													name="btn_edit"
												>
													<FontAwesomeIcon icon={faPencil} />
												</Button>
											</Tooltip>

											{dataUserProfileInfo &&
											dataUserProfileInfo.data &&
											dataUserProfileInfo.data.user_role_id != Number(4) ? (
												<Popconfirm
													title="Are you sure to deactivate this data?"
													onConfirm={() => {
														handleDeactivate(record);
													}}
													onCancel={() => {
														notification.error({
															message: "Walk-In Applicant",
															description: "Data not deactivated",
														});
													}}
													okText="Yes"
													cancelText="No"
												>
													<Button
														type="link"
														className="text-danger w-auto h-auto"
														loading={isLoadingStudentExam}
														name="btn_delete"
													>
														<FontAwesomeIcon icon={faTrash} />
													</Button>
												</Popconfirm>
											) : null}
										</>
									);
								}}
							/>
						)}

						<Table.Column
							title="Exam Category"
							key="exam_category_id"
							dataIndex="exam_category_id"
							sorter={(a, b) => a.exam_category_id - b.exam_category_id}
							render={(text, record) => {
								// console.log("record:", record);
								return <>{record.exam_category}</>;
							}}
							width={150}
						/>

						<Table.Column
							title="Exam Fee"
							key="exam_fee"
							dataIndex="exam_fee"
							sorter={(a, b) => a.exam_fee - b.exam_fee}
							width={100}
							render={(text, record) => {
								// console.log("record:", record);
								return (
									<>
										₱{" "}
										{record.exam_fee
											? parseFloat(record.exam_fee).toFixed(2)
											: "N/A"}
									</>
								);
							}}
						/>

						<Table.Column
							title="Student Year Level"
							key="student_level"
							dataIndex="student_level"
							sorter={(a, b) => a.student_level.localeCompare(b.student_level)}
							width={200}
						/>

						<Table.Column
							title="Student Status"
							key="student_status"
							dataIndex="student_status"
							sorter={(a, b) =>
								a.student_status.localeCompare(b.student_status)
							}
							width={230}
						/>

						<Table.Column
							title="Student Name"
							key="fullname"
							dataIndex="fullname"
							sorter={(a, b) => a.fullname.localeCompare(b.fullname)}
							sortDirections={["descend", "ascend"]}
							width={250}
						/>

						<Table.Column
							title="Personal Email"
							key="personal_email"
							dataIndex="personal_email"
							sorter={(a, b) =>
								a.personal_email.localeCompare(b.personal_email)
							}
							width={250}
						/>
					</Table>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							setPaginationTotal={dataSource?.data?.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper_applicant"
						/>
					</div>
				</Col>
			</Row>
		</>
	);
}
