const optionStudentStatus = [
	{
		value: "New Student",
		label: "New Student",
	},
	{
		value: "Transferee",
		label: "Transferee",
	},
	{
		value: "Returnee",
		label: "Returnee",
	},
];
export default optionStudentStatus;
