import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Popconfirm, notification } from "antd";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";

import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import validateRules from "../../../../providers/validateRules";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalAuthPassword from "../components/ModalAuthPassword";
import dayjs from "dayjs";

export default function ApplicantFormSchoolAttendedInfo(props) {
	const {
		formDisabled,
		profile_id,
		handleStudentLevelChange,
		selectedStudentLevel,
	} = props;

	const [form] = Form.useForm();
	const [formData, setFormData] = useState(null);

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const { data: dataSchoolLevel } = GET(
		`api/ref_school_level`,
		"ref_school_level"
	);

	const { data: dataSchoolName } = GET(
		`api/ref_school_name`,
		"ref_school_name"
	);

	const filteredSchoolName =
		dataSchoolName && dataSchoolName.data
			? dataSchoolName.data.filter(
					(item) => item.school_level_id === selectedStudentLevel
			  )
			: [];

	// console.log("filteredSchoolName: ", filteredSchoolName);

	const { refetch: refetchStudentSchoolAttended } = GET(
		`api/student_exams/${profile_id}`,
		["student_exams", "check_user_permission"],
		(res) => {
			if (res.data) {
				let data = res.data;

				let school_attended_list = [{}];
				if (
					data.profile_school_attendeds &&
					data.profile_school_attendeds.length > 0
				) {
					school_attended_list = data.profile_school_attendeds
						.filter((item) => item.status === 1)
						.map((item) => ({
							...item,
							year_graduated: item.year_graduated
								? dayjs(item.year_graduated, "YYYY")
								: null,
						}));
				}

				form.setFieldsValue({
					school_attended_list: school_attended_list,
				});
			}
		},
		false
	);

	useEffect(() => {
		refetchStudentSchoolAttended();
	}, [profile_id, refetchStudentSchoolAttended]);

	const {
		mutate: mutateStudentSchoolAttendedUpdate,
		isLoading: isLoadingUpdateChanges,
	} = POST(`api/update_school_attended`, "student_exams");

	const onFinish = (auth_password) => {
		let values = formData;

		let school_attended_list = values.school_attended_list.map((item) => ({
			...item,
			year_graduated: item.year_graduated
				? item.year_graduated.format("YYYY")
				: null,
		}));

		let data = {
			...values,
			school_attended_list: school_attended_list,
			profile_id: parseInt(profile_id, 10),

			// Auth Password Address for Changes
			auth_password: auth_password,
		};

		mutateStudentSchoolAttendedUpdate(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Student Profile Updated",
						description: res.message,
					});
					setToggleModalAuthPassword({
						open: false,
						data: null,
					});
				} else {
					notification.error({
						message: "Student Profile Not Updated",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const RenderInput = (props) => {
		const { formDisabled, name, restField, fields, remove } = props;

		return (
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "school_level_id"]}
						rules={[validateRules.quillValidator]}
					>
						<FloatSelect
							label="School Level"
							disabled={formDisabled}
							options={
								dataSchoolLevel && dataSchoolLevel.data
									? dataSchoolLevel.data.map((item) => ({
											value: item.id,
											label: item.school_level,
									  }))
									: []
							}
							onChange={handleStudentLevelChange}
							required={true}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Form.Item
						{...restField}
						name={[name, "school_name"]}
						rules={[validateRules.quillValidator]}
					>
						<FloatSelect
							label="School Name"
							placeholder="School Name"
							allowClear
							required={true}
							options={
								filteredSchoolName.map((item) => ({
									value: item.school_name,
									label: item.school_name,
								}))
								// .sort((a, b) => a.label.localeCompare(b.label))
							}
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "school_type"]}
						rules={[validateRules.quillValidator]}
					>
						<FloatSelect
							label="School Type"
							placeholder="School Type"
							disabled={formDisabled}
							options={[
								{ value: "Public", label: "Public" },
								{ value: "Private", label: "Private" },
							]}
							required={true}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "year_graduated"]}
						rules={[validateRules.quillValidator]}
					>
						<FloatDatePicker
							label="Year Graduated"
							placeholder="Year Graduated"
							format="YYYY"
							picker="year"
							allowClear
							disabled={formDisabled}
							required={true}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Form.Item
						{...restField}
						name={[name, "school_address"]}
						rules={[
							validateRules.quillValidator,
							validateRules.address,
							validateRules.trim,
							{
								min: 10,
								message: "Please enter at least 10 characters",
							},
						]}
					>
						<FloatInput
							label="School Address"
							placeholder="School Address"
							disabled={formDisabled}
							required={true}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<div className="action">
						{fields.length > 1 ? (
							<Popconfirm
								title="Are you sure to delete this address?"
								onConfirm={() => {
									remove(name);
								}}
								onCancel={() => {}}
								okText="Yes"
								cancelText="No"
								placement="topRight"
								okButtonProps={{
									className: "btn-main-invert",
								}}
							>
								<Button type="link" className="form-list-remove-button p-0">
									<FontAwesomeIcon icon={faTrashAlt} className="fa-lg" />
								</Button>
							</Popconfirm>
						) : null}
					</div>
				</Col>
			</Row>
		);
	};

	return (
		<Form
			form={form}
			initialValues={{
				school_attended_list: [{}],
			}}
		>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.List name="school_attended_list">
						{(fields, { add, remove }) => (
							<Row gutter={[12, 0]}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									{fields.map(({ key, name, ...restField }, index) => (
										<div key={key} className={`${index !== 0 ? "mt-25" : ""}`}>
											<RenderInput
												formDisabled={formDisabled}
												name={name}
												restField={restField}
												fields={fields}
												remove={remove}
											/>
										</div>
									))}
								</Col>

								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Button
										className="btn-main-primary mb-20 w-xs-100"
										onClick={() => {
											setFormData(form.getFieldsValue());
											setToggleModalAuthPassword({
												open: true,
											});
										}}
									>
										Change
									</Button>

									<Button
										name="add_school_attended"
										type="link"
										className="btn-main-primary p-0 ml-20"
										icon={<FontAwesomeIcon icon={faPlus} />}
										onClick={() => add()}
									>
										Add Another School Attended
									</Button>
								</Col>
								{/* Modal */}
								<ModalAuthPassword
									toggleModalAuthPassword={toggleModalAuthPassword}
									setToggleModalAuthPassword={setToggleModalAuthPassword}
									onFinish2={onFinish}
									profile_id={profile_id}
									isLoadingUpdateChanges={isLoadingUpdateChanges}
								/>
							</Row>
						)}
					</Form.List>
				</Col>
			</Row>
		</Form>
	);
}
