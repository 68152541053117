const optionStrand = [
	{ value: "NO CHOSEN", label: "NO CHOSEN STRAND" },
	{
		value: "ABM",
		label: "ABM (Accountancy, Business, and Management)",
	},
	{
		value: "AF",
		label: "AF (Arts and Design)",
	},
	{
		value: "GAS",
		label: "GAS (General Academic Strand)",
	},
	{
		value: "HE",
		label: "HE (Home Economics)",
	},

	{
		value: "HUMSS",
		label: "HUMSS (Humanities and Social Sciences)",
	},
	{
		value: "ICT",
		label: "ICT (Information and Communications Technology)",
	},
	{
		value: "STEM",
		label: "STEM (Science, Technology, Engineering, and Mathematics Strand)",
	},
	{
		value: "Tech-Voc Course",
		label: "Tech-Voc Course",
	},
	{
		value: "TVLM",
		label: "TVLM (Technical-Vocational Livelihood)",
	},
];
export default optionStrand;
